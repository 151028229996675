/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback, useMemo } from 'react';
import { QuickFilterItem } from '@hkm/components/shared/QuickFilter/QuickFilterItem';
import { disabledMenuSwipeClass } from '@hkm/features/app/panels/menu/behaviours/useSwipeEvent';
import classNames from 'classnames';

import {
  Changeable,
  Childless,
  Styleable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { FlexGap } from '@ac/web-components';

import './QuickFilter.css';

interface QuickFilterProps extends Styleable, Changeable<string[]>, Childless {
  items: Array<QuickFilterItem<string>>;
  active?: string[];
  disabled?: boolean;
}

const QuickFilter: FC<QuickFilterProps> = (props: QuickFilterProps) => {
  const activeSet = useMemo(() => {
    return new Set(props.active || []);
  }, [props.active]);

  const className = classNames(
    'quick-filter',
    disabledMenuSwipeClass,
    props.className
  );

  const toggle = useCallback(
    (filter: string) => {
      activeSet.has(filter) ? activeSet.delete(filter) : activeSet.add(filter);
      if (props.onChange) {
        props.onChange(Array.from(activeSet));
      }
    },
    [activeSet, props]
  );

  return (
    <div className={className} style={props.style}>
      <ac-flex
        class="quick-filter-scroll ac-padding-horizontal-md"
        columnGap={FlexGap.sm}
      >
        {props.items.map((item) => (
          <ac-chips
            key={item.value}
            onClick={() => toggle(item.value)}
            selected={activeSet.has(item.value)}
            disabled={props.disabled}
            data-test-selector={`quick-filter-${item.value}`}
            text={item.label}
          />
        ))}
      </ac-flex>
    </div>
  );
};

export default memo(QuickFilter);
