/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import {
  selectHousekeepingDashboardIsLoading,
  selectHousekeepingMode,
  selectHousekeepingSelectedRooms,
} from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import HousekeepingDashboardQuickFilter from '@hkm/components/Housekeeping/Dashboard/SubHeader/QuickFilter/HousekeepingDashboardQuickFilter';
import HousekeepingDashboardSortSelect from '@hkm/components/Housekeeping/Dashboard/SubHeader/SortSelector/HousekeepingDashboardSortSelect';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import classNames from 'classnames';

import { AcSubheader } from '@ac/mobile-components/dist/components/layout';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  JustifyContent,
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';
import { FlexDirection, FlexGap } from '@ac/web-components';

import './HousekeepingDashboardSubHeader.css';

export interface HousekeepingDashboardFooterSubHeaderProps {
  rooms: HousekeepingRoom[];
}

const HousekeepingDashboardSubHeader: FC<
  HousekeepingDashboardFooterSubHeaderProps
> = (props: HousekeepingDashboardFooterSubHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardMode = useSelector(selectHousekeepingMode);
  const selectedRooms = useSelector(selectHousekeepingSelectedRooms);
  const isIndeterminate =
    selectedRooms.length > 0 && selectedRooms.length !== props.rooms.length;
  const isChecked = selectedRooms.length === props.rooms.length;
  const hasBatchAccess = usePermission(...HousekeepingManagePermissionsConfig);
  const isLoading = useSelector(selectHousekeepingDashboardIsLoading);

  const prefix = 'housekeeping-dashboard-subheader';
  const classes = classNames(prefix, `dashboard-mode-${dashboardMode}`);

  const selectModeTextColor = !isLoading
    ? MobileColor.PrimaryDark
    : MobileColor.Black;

  const switchToSelectedMode = useCallback(() => {
    if (!isLoading) {
      dispatch(actions.setHousekeepingMode(DashboardMode.Selection));
    }
  }, [dispatch, isLoading]);

  const onReversSelectionChange = useCallback(() => {
    if (isIndeterminate || !isChecked) {
      dispatch(
        actions.setRoomsSelections(
          props.rooms.map((room) => ({
            id: room.id,
            numericRoomNumber: room?.roomNumber?.numberNumeric,
            roomNumber: room.roomNumber?.code ?? '',
          }))
        )
      );
    } else {
      dispatch(actions.clearHousekeepingSelection());
    }
  }, [dispatch, isChecked, isIndeterminate, props.rooms]);

  return (
    <AcSubheader className={classes} noPadding={true}>
      {dashboardMode === DashboardMode.Default && (
        <ac-flex rowGap={FlexGap.md} direction={FlexDirection.column}>
          <HousekeepingDashboardQuickFilter />
          <ac-flex
            class={`${prefix}-option ac-padding-horizontal-md`}
            justifyContent={
              hasBatchAccess
                ? JustifyContent.spaceBetween
                : JustifyContent.flexEnd
            }
            alignItems={AlignItems.center}
          >
            {hasBatchAccess && (
              <div
                onClick={switchToSelectedMode}
                {...getTestSelectorAttribute(prefix, 'switch-to-select-mode')}
              >
                <AcText
                  size={TextSize.Main2}
                  weight={TextWeight.Semibold}
                  color={selectModeTextColor}
                >
                  {t('ROOM_LIST.SELECT_PLUS_MINUS')}
                </AcText>
              </div>
            )}
            <HousekeepingDashboardSortSelect />
          </ac-flex>
        </ac-flex>
      )}

      {dashboardMode === DashboardMode.Selection && (
        <ac-flex
          justifyContent={JustifyContent.spaceBetween}
          alignItems={AlignItems.center}
        >
          <ac-checkbox
            label={t('ROOM_LIST.SUBHEADER.SELECT_ALL')}
            checked={isChecked}
            onChangeCallback={onReversSelectionChange}
            data-test-selector={`${prefix}-select-all-checkbox`}
            indeterminate={isIndeterminate}
          />
          <AcText
            size={TextSize.Main2}
            testSelector={`${prefix}-selected-items-counter`}
          >
            {t('ROOM_LIST.SUBHEADER.ITEMS_SELECTED', {
              items: selectedRooms.length,
            })}
          </AcText>
        </ac-flex>
      )}
    </AcSubheader>
  );
};

export default memo(HousekeepingDashboardSubHeader);
