import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { BadgeSize, IconName, Orientation } from '@ac/web-components';

import './Details.css';

interface ComponentProps {
  task: TaskPreviewDetails;
}

const Details: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();
  const { task } = props;

  const taskTags = useMemo(
    () =>
      (task.taskTags || []).map((tag) => (
        <ac-badge
          key={tag.id}
          content={tag.code}
          color={tag.color || BadgeColor.Black}
          size={BadgeSize.lg}
          class="ac-spacing-right-sm ac-spacing-botttom-sm"
        />
      )),
    [task.taskTags]
  );

  const reporter = useMemo(
    () =>
      task.reporterUser?.userFullName
        ? task.reporterUser?.userFullName
        : undefined,
    [task.reporterUser]
  );

  const assignee = useMemo(
    () =>
      task.assignedUser?.userFullName
        ? task.assignedUser?.userFullName
        : undefined,
    [task.assignedUser]
  );

  return (
    <AcTileShell className="task-details">
      <ac-flex direction={FlexDirection.column}>
        <ac-flex class="task-details-header">
          <ac-icon icon={IconName.task} class="ac-spacing-right-sm" />
          <AcText
            uppercase={true}
            weight={TextWeight.Semibold}
            size={TextSize.Main2}
          >
            {t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_DETAILS.TITLE')}
          </AcText>
        </ac-flex>
        <ac-text-group
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_DETAILS.TASK_TYPE')}
          orientation={Orientation.vertical}
          class="ac-spacing-vertical-sm"
          data-test-selector="task-type"
        >
          {task.taskTypeDescription}
        </ac-text-group>
        <ac-text-group
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_DETAILS.DEPARTMENT')}
          orientation={Orientation.vertical}
          class="ac-spacing-bottom-sm"
          data-test-selector="task-department"
        >
          {task.assigneeDepartmentDescription ?? '-'}
        </ac-text-group>
        <ac-text-group
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_DETAILS.DESCRIPTION')}
          orientation={Orientation.vertical}
          class="ac-spacing-bottom-sm"
          data-test-selector="task-description"
        >
          {task.description ?? '-'}
        </ac-text-group>
        <ac-text-group
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_DETAILS.TAGS')}
          orientation={Orientation.vertical}
          class="ac-spacing-bottom-sm"
        >
          <ac-flex wrapped={true} data-test-selector="task-tags">
            {taskTags.length ? taskTags : '-'}
          </ac-flex>
        </ac-text-group>
        <ac-text-group
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_DETAILS.REPORTER')}
          orientation={Orientation.vertical}
          class="ac-spacing-bottom-sm"
          data-test-selector="task-reporter"
        >
          {reporter ?? '-'}
        </ac-text-group>
        <ac-text-group
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_DETAILS.ASSIGNEE')}
          orientation={Orientation.vertical}
          data-test-selector="task-assignee"
        >
          {assignee ?? '-'}
        </ac-text-group>
      </ac-flex>
    </AcTileShell>
  );
};

export default Details;
