import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksArAccount } from '@hkm/components/TaskManagement/models/cashiering';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';

import { FlexDirection } from '@ac/mobile-components/dist/enums';
import { Orientation } from '@ac/web-components';

const ArAccount = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const account = linkedObject?.object as TasksArAccount;

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-text-group
        class="ac-spacing-top-sm ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.NAME')}
        orientation={Orientation.vertical}
        data-test-selector="task-ar-account-name"
      >
        {account.accountName ? account.accountName : -''}
      </ac-text-group>
      <ac-text-group
        class="ac-spacing-bottom-sm"
        label={t(
          'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.AR_ACCOUNT_TYPE'
        )}
        orientation={Orientation.vertical}
        data-test-selector="task-ar-account-type"
      >
        {account.arAccountType?.description ?? '-'}
      </ac-text-group>
      <Accordion
        header={
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.ACCOUNT_NUMBER'
            )}
            data-test-selector="task-ar-account-number"
          >
            {account.accountNumber ?? '-'}
          </ac-text-group>
        }
        id={account.id}
      >
        <AreaGrid>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.OPEN_BALANCE',
              { currency: account.balance?.currencyCode }
            )}
            data-test-selector="task-ar-account-balance"
          >
            {account.balance?.amount ?? '-'}
          </ac-text-group>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.CREDIT_LIMIT',
              { currency: account.creditLimit?.currencyCode }
            )}
            data-test-selector="task-ar-account-credit-limit"
          >
            {account.creditLimit?.amount ?? '-'}
          </ac-text-group>
          <ac-text-group
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.INVOICES')}
            orientation={Orientation.vertical}
            data-test-selector="task-ar-account-unsettled-invoices"
          >
            {account.unsettledInvoices > 0 ? account.unsettledInvoices : '-'}
          </ac-text-group>
          <ac-text-group
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.AR_ACCOUNT.CONTACT_PERSON'
            )}
            orientation={Orientation.vertical}
            data-test-selector="task-ar-account-contact"
          >
            {account.contact?.fullName ?? '-'}
          </ac-text-group>
        </AreaGrid>
      </Accordion>
    </ac-flex>
  );
};

export default ArAccount;
