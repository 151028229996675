import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InnerAccordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/InnerAccordion/InnerAccordion';
import SpecialNeeds from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/SpecialNeeds/SpecialNeeds';
import VipAndMember from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/VipAndMember/VipAndMember';

import { ReservationAccompanyingGuestDashboardItem } from '@ac/library-api';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { MobileColor, TextSize } from '@ac/mobile-components/dist/enums';
import { IconName, Orientation } from '@ac/web-components';

interface ComponentProps {
  guest: ReservationAccompanyingGuestDashboardItem;
  index: number;
}

const ReservationAccompanyingGuest = (props: ComponentProps) => {
  const { guest, index } = props;
  const { t } = useTranslation();
  const requireAccordion =
    guest.hasDisability ||
    !!guest.vipStatusCode ||
    !!guest.activeMembershipLevelCodes.length;

  const guestInfo = useMemo(
    () => (
      <>
        <AcText
          size={TextSize.Main2}
          className="ac-spacing-right-sm"
          testSelector={`task-accompanying-guest-name-${index}`}
        >
          {guest.formattedName}
        </AcText>

        {(!!guest.vipStatusCode ||
          !!guest.activeMembershipLevelCodes.length) && (
          <AcIndicator
            className="ac-spacing-right-sm"
            icon={IconName.vip}
            backgroundColor={MobileColor.Warning}
            testSelector={`task-accompanying-guest-vip-indicator-${index}`}
          />
        )}
        {guest.hasDisability && (
          <AcIndicator
            icon={IconName.disability}
            backgroundColor={MobileColor.Alert}
            testSelector={`task-accompanying-guest-disability-indicator-${index}`}
          />
        )}
      </>
    ),
    [guest, index]
  );

  return (
    <>
      {requireAccordion ? (
        <InnerAccordion header={guestInfo} id={guest?.id || ''}>
          {(!!guest.vipStatusCode ||
            !!guest.activeMembershipLevelCodes.length) && (
            <ac-text-group
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.VIP_AND_MEMBERSHIP'
              )}
              orientation={Orientation.vertical}
              class="ac-spacing-top-md"
            >
              <VipAndMember
                vipStatusCode={guest.vipStatusCode}
                memberships={guest.activeMembershipLevelCodes}
              />
            </ac-text-group>
          )}
          {guest.hasDisability && (
            <ac-text-group
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.SPECIAL_NEEDS'
              )}
              orientation={Orientation.vertical}
            >
              <SpecialNeeds disabilityCodes={guest.disabilityCodes} />
            </ac-text-group>
          )}
        </InnerAccordion>
      ) : (
        guestInfo
      )}
    </>
  );
};

export default ReservationAccompanyingGuest;
