/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { HousekeepingBatchStatusChangeData } from '@hkm/components/Housekeeping/shared/models/housekeepingBatchStatusChangeData';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel,
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { useEnabledRoomStates } from '@hkm/shared/hooks/useEnabledRoomStates';
import {
  formatNumberRanges,
  parseNumberRanges,
} from '@hkm/shared/numberRange/numberRangesParser';
import { getFieldErrorMessage } from '@hkm/shared/validation/errorProvider';

import { DictionaryEntity, RoomStatus } from '@ac/library-api';
import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import {
  AcSelect,
  AcSelectValue,
} from '@ac/mobile-components/dist/components/select';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formFieldFactory, FormRenderProps } from '@ac/react-infrastructure';
import { FlexDirection, FlexGap } from '@ac/web-components';

interface BatchStatusChangeBodyProps extends Childless {
  formProps: FormRenderProps<HousekeepingBatchStatusChangeData>;
  disableRoomList: boolean;
}

const FormField = formFieldFactory<HousekeepingBatchStatusChangeData>();

/* tslint:disable:jsx-no-lambda */
const BatchStatusChangeBody: FC<BatchStatusChangeBodyProps> = (
  props: BatchStatusChangeBodyProps
) => {
  const prefix = 'batch-status-change-body';
  const { t } = useTranslation();
  const states = useEnabledRoomStates();

  const roomStates: AcSelectValue[] = states.map((state: DictionaryEntity) => ({
    value: state.code ?? '',
    itemLabel: extractDictionaryEntryLabel(state, DictionaryLabelLength.Long),
  }));

  function onRoomRangeBlur() {
    const parsed = parseNumberRanges(props.formProps.values.roomRanges);
    if (parsed) {
      props.formProps.form.change('roomRanges', formatNumberRanges(parsed));
    }
  }

  function changeHousekeepingStates(newStatus: RoomStatus) {
    props.formProps.form.change('roomStates', newStatus);
  }

  return (
    <AcBody>
      <ac-flex direction={FlexDirection.column} rowGap={FlexGap.md}>
        <FormField valuePath="roomRanges">
          {(fieldRenderProps) => (
            <AcFieldText
              {...fieldRenderProps.input}
              required={true}
              onBlur={onRoomRangeBlur}
              disabled={props.disableRoomList}
              placeholder={t('GLOBAL.FILL')}
              testSelector={`${prefix}-room-ranges`}
              validation={getFieldErrorMessage(fieldRenderProps)}
              label={t('HOUSEKEEPING_BATCH.STATUS_CHANGE.ROOM_LIST')}
            />
          )}
        </FormField>

        <FormField valuePath="roomStates">
          {(fieldRenderProps) => {
            return (
              <AcSelect
                showInputs={true}
                required={true}
                clearable={true}
                itemsList={roomStates}
                onChange={changeHousekeepingStates}
                placeholder={t('GLOBAL.SELECT')}
                label={t('GLOBAL.ROOM_STATUS.LONG')}
                testSelector={`${prefix}-housekeepingStates`}
                validation={getFieldErrorMessage(fieldRenderProps)}
                selectedItem={fieldRenderProps.input.value as string}
                itemTemplate={(item) => (
                  <RoomStatusBadge
                    status={item.value as RoomStatus}
                    showLabel={true}
                  />
                )}
              />
            );
          }}
        </FormField>
      </ac-flex>
    </AcBody>
  );
};

export default memo(BatchStatusChangeBody);
