/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGuestServiceValue } from '@hkm/components/Attendant/shared/utils/useGuestServiceValue';
import {
  selectActiveProperty,
  selectBusinessDate,
  selectPropertyDateFormats,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceRoom } from '@hkm/components/shared/GuestService/GuestServiceField';
import {
  GuestServiceModalFormState,
  useFormState,
} from '@hkm/components/shared/GuestService/modal/form/useFormState';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { noop } from '@hkm/shared/helpers/noop';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import {
  createLocalTimeFromTzTime,
  createTimeWithTimezone,
} from '@hkm/utils/dateHelper';
import dayjs from 'dayjs';

import { DictionaryEntity } from '@ac/library-api';
import { DateUtils } from '@ac/library-utils/dist/utils';
import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcFieldTime,
  defaultOutputFormat,
} from '@ac/mobile-components/dist/components/field';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import {
  Form,
  formFieldFactory,
  FormRenderProps,
} from '@ac/react-infrastructure';
import { FlexGap, IconName, Orientation } from '@ac/web-components';

import './GuestServiceModal.css';

export enum GuestServiceModalMode {
  Planned = 'planned',
  AdHoc = 'adHoc',
  AdHocPlanned = 'adHocPlanned',
}

export interface GuestServiceModalProps extends Testable {
  mode: GuestServiceModalMode | undefined;
  description?: string;
  room: GuestServiceRoom;
  selectedGuestService?: GuestServiceStatusType;
  excludeGuestService?: GuestServiceStatusType[];

  onClose(): void;

  onConfirm(guestServiceModalFormState: GuestServiceModalFormState): void;
}

const HOUR_OFFSET = 1;
const TEXTAREA_MAX_LENGTH = 200;

const FormField = formFieldFactory<GuestServiceModalFormState>();

// tslint:disable:jsx-no-lambda
const GuestServiceModal = (props: GuestServiceModalProps) => {
  const { t } = useTranslation();
  const prefix = 'guest-service-modal';
  const formats = useSelector(selectPropertyDateFormats);
  const businessDate = useSelector(selectBusinessDate) ?? '';
  const { initialize, state } = useFormState();
  const property = useSelector(selectActiveProperty);
  const { getServiceValue, getPlannedServiceValue, getAdHocServiceValue } =
    useGuestServiceValue();
  const {
    filteredGuestServices,
    activePlannedGuestServices,
    activeAdHocGuestServices,
    activeGuestServices,
  } = useGuestServices();

  const onItemClick = (
    formRenderProps: FormRenderProps<GuestServiceModalFormState>,
    status: GuestServiceStatusType
  ) => {
    formRenderProps.form.change('serviceType', status);
  };

  const convertTimeToTimeWithTimezone = useCallback(
    (timeString: string) => {
      return createTimeWithTimezone(timeString, property?.timeZoneCode).format(
        defaultOutputFormat
      );
    },
    [property?.timeZoneCode]
  );

  const onConfirm = useCallback(
    (formRenderProps: FormRenderProps<GuestServiceModalFormState>) => {
      const { serviceDeferredUntil, servicePreferredAfterTime, serviceType } =
        formRenderProps.values;
      props.onConfirm({
        ...formRenderProps.values,
        servicePreferredAfterTime:
          servicePreferredAfterTime &&
          serviceType === GuestServiceStatusType.ServicePreferred
            ? convertTimeToTimeWithTimezone(servicePreferredAfterTime)
            : undefined,
        serviceDeferredUntil:
          serviceDeferredUntil &&
          serviceType === GuestServiceStatusType.ServiceDeferred
            ? convertTimeToTimeWithTimezone(serviceDeferredUntil)
            : undefined,
      });
      props.onClose();
    },
    [props, convertTimeToTimeWithTimezone]
  );

  const onFocusDeferredTime = useCallback(
    (formRenderProps: FormRenderProps<GuestServiceModalFormState>) => {
      // IOS hack: otherwise date won't change
      if (
        !formRenderProps.values.serviceDeferredUntil ||
        !dayjs(formRenderProps.values.serviceDeferredUntil).isAfter(new Date())
      ) {
        const date = dayjs()
          .add(HOUR_OFFSET, 'hour')
          .format(defaultOutputFormat);
        formRenderProps.form.change('serviceDeferredUntil', date);
      }
    },
    []
  );

  const onFocusPreferredTime = useCallback(
    (formRenderProps: FormRenderProps<GuestServiceModalFormState>) => {
      // IOS hack: otherwise date won't change
      if (
        !formRenderProps.values.servicePreferredAfterTime ||
        !dayjs(formRenderProps.values.servicePreferredAfterTime).isAfter(
          new Date()
        )
      ) {
        const date = dayjs()
          .add(HOUR_OFFSET, 'hour')
          .format(defaultOutputFormat);
        formRenderProps.form.change('servicePreferredAfterTime', date);
      }
    },
    []
  );

  const isDateValid = useCallback(
    (formRenderProps: FormRenderProps<GuestServiceModalFormState>) => {
      const { serviceDeferredUntil, servicePreferredAfterTime } =
        formRenderProps.values;
      const isDeferredDateValid =
        formRenderProps.values.serviceType ===
          GuestServiceStatusType.ServiceDeferred && serviceDeferredUntil
          ? dayjs(serviceDeferredUntil).isAfter(new Date())
          : true;
      const isPreferredDateValid =
        formRenderProps.values.serviceType ===
          GuestServiceStatusType.ServicePreferred && servicePreferredAfterTime
          ? dayjs(servicePreferredAfterTime).isAfter(new Date())
          : true;

      return isDeferredDateValid && isPreferredDateValid;
    },
    []
  );

  const modalTitle = useMemo(() => {
    if (props.mode === GuestServiceModalMode.AdHoc) {
      return t('GLOBAL.GUEST_SERVICE_REQUEST.TITLE');
    }

    return t('GLOBAL.GUEST_SERVICE_STATUS.TITLE');
  }, [props.mode, t]);

  const isArrivalOrDepartureDate = useMemo(() => {
    return (
      DateUtils.comparison.isSameDay(
        businessDate ?? '',
        props.room.currentMainReservation?.arrivalDate ?? ''
      ) ||
      DateUtils.comparison.isSameDay(
        businessDate ?? '',
        props.room.currentMainReservation?.departureDate ?? ''
      )
    );
  }, [
    businessDate,
    props.room.currentMainReservation?.arrivalDate,
    props.room.currentMainReservation?.departureDate,
  ]);

  const serviceOptions = useMemo(() => {
    let availableServices;
    if (props.mode === GuestServiceModalMode.Planned) {
      availableServices = activePlannedGuestServices;
    }
    if (props.mode === GuestServiceModalMode.AdHoc) {
      availableServices = activeAdHocGuestServices;
    }
    if (props.mode === GuestServiceModalMode.AdHocPlanned) {
      availableServices = activeGuestServices;
    }

    const excludedServices = props.excludeGuestService ?? [];

    if (isArrivalOrDepartureDate) {
      excludedServices.push(
        GuestServiceStatusType.ServiceDeferred,
        GuestServiceStatusType.ServicePreferred
      );
    }

    return filteredGuestServices(excludedServices, availableServices);
  }, [
    activeGuestServices,
    activeAdHocGuestServices,
    filteredGuestServices,
    activePlannedGuestServices,
    props.excludeGuestService,
    props.mode,
    isArrivalOrDepartureDate,
  ]);

  const serviceTypeValue = useMemo(() => {
    if (props.mode === GuestServiceModalMode.Planned) {
      return getPlannedServiceValue(props.room)?.code;
    }
    if (props.mode === GuestServiceModalMode.AdHoc) {
      return getAdHocServiceValue(props.room)?.code;
    }

    return getServiceValue(props.room)?.code;
  }, [
    getAdHocServiceValue,
    getPlannedServiceValue,
    getServiceValue,
    props.mode,
    props.room,
  ]);

  // reset data
  useEffect(() => {
    if (props.mode) {
      initialize({
        serviceDeferredUntil: createLocalTimeFromTzTime(
          props.room.serviceDeferredUntil,
          property?.timeZoneCode
        ),
        serviceType: serviceTypeValue as GuestServiceStatusType,
        initialServiceType: serviceTypeValue as GuestServiceStatusType,
        servicePreferredAfterTime: createLocalTimeFromTzTime(
          props.room.servicePreferredAfterTime,
          property?.timeZoneCode
        ),
        instruction: props.room.serviceRequestedInstruction,
      });
    }
  }, [
    props.room.serviceDeferredUntil,
    props.mode,
    props.selectedGuestService,
    props.room,
    initialize,
    getServiceValue,
    serviceTypeValue,
    property?.timeZoneCode,
  ]);

  if (!props.mode) {
    return null;
  }

  return (
    <Form initialValues={state} onSubmit={noop}>
      {(formRenderProps) => (
        <AcModal className={prefix} isOpen={true} onClose={props.onClose}>
          <AcModalHeader showCloseButton={true}>{modalTitle}</AcModalHeader>
          <AcModalBody className={`${prefix}-body`}>
            <ac-flex direction={FlexDirection.column} grow={true}>
              <AcText className="ac-spacing-left-md" size={TextSize.Main2}>
                {t('GLOBAL.GUEST_SERVICE_STATUS.DESCRIPTION')}
              </AcText>
              {props.description && (
                <div className={`${prefix}-description`}>
                  <AcText size={TextSize.Main1}>{props.description}</AcText>
                </div>
              )}
              <ul className={`${prefix}-list`}>
                {serviceOptions.map(
                  (guestService: DictionaryEntity, key: number) => {
                    return (
                      <li
                        key={key}
                        className={`${prefix}-list-item`}
                        onClick={() =>
                          onItemClick(
                            formRenderProps,
                            guestService.code as GuestServiceStatusType
                          )
                        }
                      >
                        <ac-flex
                          class="ac-padding-md"
                          rowGap={FlexGap.lg}
                          columnGap={FlexGap.lg}
                          direction={FlexDirection.column}
                          grow={true}
                          alignItems={AlignItems.flexStart}
                        >
                          <FormField valuePath="serviceType">
                            {(fieldRenderProps) => (
                              <ac-radio-button
                                label={guestService.name}
                                name="guest-service"
                                checked={
                                  fieldRenderProps.input.value ===
                                  guestService.code
                                }
                                data-test-selector={formatTestSelector(
                                  prefix,
                                  guestService.name
                                    ?.toLocaleLowerCase()
                                    .replace(' ', '-'),
                                  'radio'
                                )}
                              />
                            )}
                          </FormField>

                          {props.mode === GuestServiceModalMode.AdHocPlanned &&
                            guestService.code ===
                              GuestServiceStatusType.ServiceDeferred && (
                              <ac-text-group
                                class="ac-spacing-top-s"
                                label={t(
                                  'GLOBAL.GUEST_SERVICE_STATUS.PREFERRED_TIME_OPTIONAL'
                                )}
                              >
                                <FormField valuePath="serviceDeferredUntil">
                                  {(fieldRenderProps) => (
                                    <AcFieldTime
                                      format={formats.time}
                                      value={fieldRenderProps.input.value}
                                      selectable={true}
                                      onFocus={() =>
                                        onFocusDeferredTime(formRenderProps)
                                      }
                                      onChange={
                                        fieldRenderProps.input.onChangeCallback
                                      }
                                      testSelector={formatTestSelector(
                                        prefix,
                                        'time-input'
                                      )}
                                      validation={
                                        !isDateValid(formRenderProps)
                                          ? t(
                                              'GLOBAL.GUEST_SERVICE_STATUS.IN_THE_PAST_ERROR'
                                            )
                                          : ''
                                      }
                                    />
                                  )}
                                </FormField>
                              </ac-text-group>
                            )}
                          {props.mode === GuestServiceModalMode.Planned &&
                            guestService.code ===
                              GuestServiceStatusType.ServicePreferred &&
                            formRenderProps.values.serviceType ===
                              GuestServiceStatusType.ServicePreferred && (
                              <ac-text-group
                                class="ac-spacing-top-s"
                                orientation={Orientation.vertical}
                                label={t(
                                  'GLOBAL.GUEST_SERVICE_STATUS.PREFERRED_TIME'
                                )}
                              >
                                <FormField valuePath="servicePreferredAfterTime">
                                  {(fieldRenderProps) => (
                                    <AcFieldTime
                                      format={formats.time}
                                      value={fieldRenderProps.input.value}
                                      onFocus={() =>
                                        onFocusPreferredTime(formRenderProps)
                                      }
                                      selectable={true}
                                      onChange={
                                        fieldRenderProps.input.onChangeCallback
                                      }
                                      testSelector={formatTestSelector(
                                        prefix,
                                        'time-input'
                                      )}
                                      validation={
                                        !isDateValid(formRenderProps) &&
                                        fieldRenderProps.input.value
                                          ? t(
                                              'GLOBAL.GUEST_SERVICE_STATUS.IN_THE_PAST_ERROR'
                                            )
                                          : ''
                                      }
                                    />
                                  )}
                                </FormField>
                              </ac-text-group>
                            )}
                          {props.mode === GuestServiceModalMode.AdHoc &&
                            guestService.code ===
                              GuestServiceStatusType.ServiceDeferred &&
                            formRenderProps.values.serviceType ===
                              GuestServiceStatusType.ServiceDeferred && (
                              <ac-text-group
                                class="ac-spacing-top-s"
                                label={t(
                                  'GLOBAL.GUEST_SERVICE_REQUEST.CLEAN_AFTER_OPTIONAL'
                                )}
                              >
                                <FormField valuePath="serviceDeferredUntil">
                                  {(fieldRenderProps) => (
                                    <AcFieldTime
                                      format={formats.time}
                                      value={fieldRenderProps.input.value}
                                      onFocus={() =>
                                        onFocusDeferredTime(formRenderProps)
                                      }
                                      selectable={true}
                                      onChange={
                                        fieldRenderProps.input.onChangeCallback
                                      }
                                      testSelector={formatTestSelector(
                                        prefix,
                                        'time-input'
                                      )}
                                      validation={
                                        !isDateValid(formRenderProps)
                                          ? t(
                                              'GLOBAL.GUEST_SERVICE_STATUS.IN_THE_PAST_ERROR'
                                            )
                                          : ''
                                      }
                                    />
                                  )}
                                </FormField>
                              </ac-text-group>
                            )}
                          {props.mode === GuestServiceModalMode.AdHoc &&
                            guestService.code ===
                              GuestServiceStatusType.ServiceRequested &&
                            formRenderProps.values.serviceType ===
                              GuestServiceStatusType.ServiceRequested && (
                              <FormField valuePath="instruction">
                                {(fieldRenderProps) => (
                                  <ac-textarea
                                    {...fieldRenderProps.input}
                                    label={t(
                                      'GLOBAL.GUEST_SERVICE_REQUEST.INSTRUCTION_OPTIONAL'
                                    )}
                                    maxLength={TEXTAREA_MAX_LENGTH}
                                    data-test-selector={formatTestSelector(
                                      prefix,
                                      'instruction-input'
                                    )}
                                  />
                                )}
                              </FormField>
                            )}
                        </ac-flex>
                      </li>
                    );
                  }
                )}
              </ul>
            </ac-flex>
          </AcModalBody>

          <AcModalFooter>
            <ac-flex
              direction={FlexDirection.column}
              grow={true}
              justifyContent={JustifyContent.center}
              alignItems={AlignItems.center}
            >
              <AcButton
                onClick={() => onConfirm(formRenderProps)}
                fullwidth={true}
                disabled={
                  (formRenderProps.values.instruction?.length ?? 0) >
                    TEXTAREA_MAX_LENGTH ||
                  !formRenderProps.values.serviceType ||
                  !isDateValid(formRenderProps) ||
                  (formRenderProps.values.serviceType ===
                    GuestServiceStatusType.ServicePreferred &&
                    !formRenderProps.values.servicePreferredAfterTime)
                }
              >
                <AcButtonContent text={t('GLOBAL.CONFIRM')} />
              </AcButton>
              <AcButton
                fullwidth={true}
                pattern={AcButtonPattern.Tertiary}
                onClick={props.onClose}
              >
                <AcButtonContent
                  icon={IconName.cancel}
                  text={t('GLOBAL.CANCEL')}
                />
              </AcButton>
            </ac-flex>
          </AcModalFooter>
        </AcModal>
      )}
    </Form>
  );
};

export default memo(GuestServiceModal);
