/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoomConditionsBadge } from '@hkm/components/shared/RoomConditions/RoomConditionsBadge';
import { useAcSelectTranslations } from '@hkm/features/translations/useAcSelectTranslations';

import { IdCodeDescriptionColorDto } from '@ac/library-api';
import { AcMultiSelect } from '@ac/mobile-components/dist/components/multiselect';

interface Props {
  isReadOnly: boolean;
  roomConditions: Array<{ value: string; itemLabel: string }>;
  selectedRoomConditions: Array<IdCodeDescriptionColorDto<string>> | undefined;
  testSelectorPrefix: string;
  onRoomConditionsChange(newSelectedConditions: string[]): void;
}

export const RoomConditionsField = ({
  roomConditions,
  selectedRoomConditions,
  testSelectorPrefix,
  onRoomConditionsChange,
  isReadOnly,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { translations } = useAcSelectTranslations();
  const selectedRoomConditionsList = (selectedRoomConditions || []).map(
    ({ id }) => id
  );

  if (isReadOnly && !selectedRoomConditions) {
    return null;
  }

  if (isReadOnly) {
    return (
      <ac-text-group
        label={t('ROOM_DETAILS.ROOM_CONDITIONS')}
        data-test-selector={`${testSelectorPrefix}-room-conditions`}
      >
        <RoomConditionsBadge roomConditions={selectedRoomConditions} />
      </ac-text-group>
    );
  }

  return (
    <AcMultiSelect
      placeholder={t('GLOBAL.SELECT')}
      label={t('ROOM_DETAILS.ROOM_CONDITIONS')}
      itemsList={roomConditions}
      selectedItem={selectedRoomConditionsList}
      testSelector={`${testSelectorPrefix}-room-conditions`}
      showInputs
      showSearch
      onChange={onRoomConditionsChange}
      translations={translations}
    />
  );
};
