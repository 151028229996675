/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback, useMemo } from 'react';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { TaskQuickFilterItem } from '@hkm/components/TaskManagement/domain/interfaces/taskQuickFilterItem';

import '@hkm/components/shared//QuickFilter/QuickFilter.css';

interface DashboardQuickFilterItemProps {
  quickFilter: TaskQuickFilterItem;
  applyQuickFilter: (quickFilter: TaskQuickFilter) => void;
  appliedQuickFilter?: TaskQuickFilter;
}

const DashboardQuickFilterItem: FC<DashboardQuickFilterItemProps> = ({
  quickFilter,
  applyQuickFilter,
  appliedQuickFilter,
}: DashboardQuickFilterItemProps) => {
  const isActive: boolean = useMemo(
    () => quickFilter.value === appliedQuickFilter,
    [appliedQuickFilter, quickFilter.value]
  );

  const toggleQuickFilter = useCallback(
    () => applyQuickFilter(quickFilter.value),
    [applyQuickFilter, quickFilter.value]
  );

  return (
    <ac-chips
      onClick={toggleQuickFilter}
      data-test-selector={`task-quick-filter-${quickFilter.value}`}
      text={quickFilter.label}
      selected={isActive}
    />
  );
};

export default memo(DashboardQuickFilterItem);
