/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { FC, memo, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DashboardItemActionButton from '@hkm/components/TaskManagement/Dashboard/DashboardItem/DashboardItemActionButton/DashboardItemActionButton';
import { getPropertyShortDateFormat } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { TaskDashboardItemWithFlag } from '@hkm/components/TaskManagement/domain/interfaces/taskDashboardItemWithFlag';
import EscalatedFlag from '@hkm/components/TaskManagement/shared/components/EscalatedFlag';
import OverdueFlag from '@hkm/components/TaskManagement/shared/components/OverdueFlag';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import DateTimeHelpers from '@hkm/components/TaskManagement/utils/dateTime';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';

import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { BadgeSize, IconName, Orientation } from '@ac/web-components';

import './TaskOverview.css';

interface ComponentProps {
  task: TaskDashboardItemWithFlag | TaskPreviewDetails;
  index: number;
  dashboardView?: boolean;
  actionsButtonRef?: RefObject<HTMLDivElement>;
}

const DashboardItem: FC<ComponentProps> = (props) => {
  const { task, index, actionsButtonRef } = props;
  const { t } = useTranslation();
  const shortDateFormat = useSelector(getPropertyShortDateFormat);

  const dueDate = useMemo((): string | undefined => {
    if (!shortDateFormat) {
      return '';
    }

    return (
      task.dueDate &&
      DateTimeHelpers.formatDateForField(
        task.dueDate,
        shortDateFormat.toUpperCase()
      )
    );
  }, [shortDateFormat, task.dueDate]);

  const dueTime = useMemo((): string | undefined => {
    if (!task.dueTime) {
      return;
    }

    return DateTimeHelpers.formatTime(task.dueTime);
  }, [task.dueTime]);

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-flex
        class="task-header"
        justifyContent={JustifyContent.spaceBetween}
        alignItems={AlignItems.center}
      >
        {props.dashboardView ? (
          <>
            <AcText
              className="task-id-and-name"
              size={TextSize.Main2}
              color={MobileColor.Gray1}
              weight={TextWeight.Semibold}
              testSelector={`task-item-id-and-name-${index}`}
            >
              {task.number} {task.name}
            </AcText>
            <DashboardItemActionButton
              task={task}
              index={index}
              actionsButtonRef={actionsButtonRef}
            />
          </>
        ) : (
          <ac-flex>
            <ac-icon icon={IconName.activities} class="ac-spacing-right-sm" />
            <AcText
              uppercase={true}
              weight={TextWeight.Semibold}
              size={TextSize.Main2}
            >
              {t('GLOBAL.OVERVIEW')}
            </AcText>
          </ac-flex>
        )}
      </ac-flex>
      {!props.dashboardView && (
        <ac-text-group
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_NAME')}
          orientation={Orientation.vertical}
          class="ac-spacing-top-md task-name"
        >
          {task.name}
        </ac-text-group>
      )}
      <div>
        <ac-flex direction={FlexDirection.row} class="ac-spacing-bottom-sm">
          <ac-box sizeSm={8}>
            <ac-flex direction={FlexDirection.column}>
              <ac-text-group
                label={t('TASK_MANAGEMENT.DASHBOARD.LABELS.STATUS')}
                orientation={Orientation.vertical}
              >
                <ac-badge
                  content={task.taskStatus.code}
                  color={task.taskStatus.color || BadgeColor.Black}
                  size={BadgeSize.lg}
                  text={task.taskStatusDescription}
                  data-test-selector={`task-item-status-${index}`}
                />
              </ac-text-group>
            </ac-flex>
          </ac-box>
          <ac-box sizeSm={8}>
            <ac-flex direction={FlexDirection.column}>
              <ac-text-group
                label={t('TASK_MANAGEMENT.DASHBOARD.LABELS.PRIORITY')}
                orientation={Orientation.vertical}
              >
                <ac-badge
                  content={task.taskPriority.code}
                  color={task.taskPriority.color || BadgeColor.Black}
                  size={BadgeSize.lg}
                  data-test-selector={`task-item-priority-${index}`}
                />
              </ac-text-group>
            </ac-flex>
          </ac-box>
        </ac-flex>
        <ac-flex direction={FlexDirection.row} class="ac-spacing-bottom-s">
          <ac-box sizeSm={8}>
            <ac-flex direction={FlexDirection.column}>
              <ac-text-group
                orientation={Orientation.vertical}
                label={t('TASK_MANAGEMENT.DASHBOARD.LABELS.DUE_DATE')}
              >
                <AcText
                  size={TextSize.Main1}
                  testSelector={'task-item-due-date'}
                >
                  {dueDate}
                </AcText>
              </ac-text-group>
            </ac-flex>
          </ac-box>
          <ac-box sizeSm={8}>
            <ac-flex direction={FlexDirection.column}>
              <ac-text-group
                orientation={Orientation.vertical}
                label={t('TASK_MANAGEMENT.DASHBOARD.LABELS.DUE_TIME')}
              >
                <AcText
                  size={TextSize.Main1}
                  testSelector={'task-item-due-time'}
                >
                  {dueTime}
                </AcText>
              </ac-text-group>
            </ac-flex>
          </ac-box>
        </ac-flex>
        {task.isOverdue && (
          <OverdueFlag indexId={`task-management-task-${index}`} />
        )}
        {task.isEscalated && (
          <EscalatedFlag indexId={`task-management-task-${index}`} />
        )}
      </div>
    </ac-flex>
  );
};

export default memo(DashboardItem);
