/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { ToastSelectors } from '@hkm/features/notifications/Notifications.selectors';
import { useNotificationsContext } from '@hkm/features/notifications/store/context';

export const Notifications = (): JSX.Element => {
  const { setRef } = useNotificationsContext();

  return (
    <ac-toast
      ref={setRef}
      data-test-selector={ToastSelectors.toast}
      mobile={true}
    />
  );
};
