import { useCallback } from 'react';
import { useNotificationsContext } from '@hkm/features/notifications/store/context';

import {
  ToastPresenterInContainer,
  WithoutSeverityToast,
} from '@ac/react-infrastructure/dist/features/notifications/toast';
import { ToastInterface, Translation } from '@ac/web-components';

interface Output {
  createToastObject(): ToastPresenterInContainer;
}

export function useContainerToast(): Output {
  const { show, showError, showWarning, showSuccess } =
    useNotificationsContext();

  const resolveToast = useCallback(
    (toast: Translation | WithoutSeverityToast): WithoutSeverityToast => {
      return typeof toast === 'object'
        ? (toast as WithoutSeverityToast)
        : { message: toast.toString() };
    },
    []
  );

  const createToastObject = useCallback((): ToastPresenterInContainer => {
    return {
      show: (toast: ToastInterface) => {
        show(toast);
      },
      showSuccess: (toast: Translation | WithoutSeverityToast) => {
        showSuccess(resolveToast(toast));
      },
      showError: (toast: Translation | WithoutSeverityToast) => {
        showError(resolveToast(toast));
      },
      showWarning: (toast: Translation | WithoutSeverityToast) => {
        showWarning(resolveToast(toast));
      },
    };
  }, [show, showSuccess, resolveToast, showError, showWarning]);

  return {
    createToastObject,
  };
}
