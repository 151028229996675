import { Ref } from 'react';
import { ReservationNotes } from '@hkm/components/App/domain/interfaces/BookingNotesStore';
import { DictionariesStore } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import { DictionaryConfig } from '@hkm/shared/dictionaries/dictionariesConfig';
import { Dictionary } from '@hkm/shared/dictionaries/dictionary';
import { ErrorWithMessage } from '@hkm/shared/helpers/getErrorMessage';
import { ForceRequest } from '@hkm/shared/interfaces/forceRequest';

import {
  CustomerDetails,
  FeatureToggleItem,
  RoomQueueDetailsViewsDto,
} from '@ac/library-api';
import {
  createAction,
  getListAction,
  getOneAction,
} from '@ac/library-utils/dist/redux-utils';

export const fetchDictionaries = getOneAction<
  Set<Dictionary | DictionaryConfig>,
  DictionariesStore
>('app/dictionaries');
export const fetchFeatureToggles = getOneAction<undefined, FeatureToggleItem[]>(
  'app/featureToggles'
);
export const clearDictionaries = createAction<ForceRequest>(
  'app/clearDictionaries'
);
export const clearAllViews = createAction('app/clearAllViews');

export const displayExtractedError = createAction<ErrorWithMessage>(
  'app/overlayMessage/displayExtractedError'
);
export const displayError = createAction<string>(
  'app/overlayMessage/displayError'
);
export const displayWarning = createAction<string>(
  'app/overlayMessage/displayWarning'
);
export const displaySuccess = createAction<string>(
  'app/overlayMessage/displaySuccess'
);
export const registerBottomMessageOffsetRef = createAction<Ref<HTMLElement>>(
  'app/overlayMessage/registerBottomMessageOffsetRef'
);
export const unregisterBottomMessageOffsetRef = createAction<Ref<HTMLElement>>(
  'app/overlayMessage/unregisterBottomMessageOffsetRef'
);

export const fetchCurrentCustomer = getOneAction<undefined, CustomerDetails>(
  'app/customer/fetchCurrentCustomer'
);

export const fetchReservationNotes = getListAction<
  string[],
  ReservationNotes[]
>('app/notes/fetchReservationNotes');
export const clearReservationNotes = createAction<undefined>(
  'app/notes/clearReservationNotes'
);

export const fetchRoomQueue = getOneAction<undefined, RoomQueueDetailsViewsDto>(
  'housekeeping/dashboard/fetchRoomQueue'
);
