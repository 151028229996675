/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as detailsActions from '@hkm/components/Housekeeping/Details/domain/actions';
import { selectHousekeepingDetailsState } from '@hkm/components/Housekeeping/Details/domain/selectors';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import * as createMaintenanceActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/actions';
import { selectMaintenanceCreateState } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/selectors';
import MaintenanceForm from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/MaintenanceCreateForm';
import { MaintenanceCreateFieldDisability } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/MaintenanceCreateFieldDisability';
import APP_ROUTES from '@hkm/constants/routing.constants';

import { SetMaintenances } from '@ac/library-api';
import { useComponentDidUpdateEffect } from '@ac/mobile-components/dist/hooks';

const HousekeepingCreateMaintenance: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const { roomId } = params;
  const businessDate = useSelector(selectBusinessDate) ?? '';
  const roomState = useSelector(selectHousekeepingDetailsState);
  const maintenanceCreateState = useSelector(selectMaintenanceCreateState);

  const backward = useCallback(() => {
    navigate(APP_ROUTES.HOUSEKEEPING.DASHBOARD);
  }, [navigate]);

  useEffect(() => {
    if (roomId) {
      dispatch(detailsActions.reset());
      dispatch(detailsActions.initialize(roomId));
    }

    // eslint-disable-next-line
  }, [roomId]);

  const onSubmit = useCallback(
    (data: SetMaintenances) => {
      dispatch(
        createMaintenanceActions.setMaintenanceActionsSet.createMaintenance.trigger(
          {
            data,
          }
        )
      );
    },
    [dispatch]
  );

  const onValidateMaintenance = useCallback(
    (data: SetMaintenances) => {
      dispatch(
        createMaintenanceActions.setMaintenanceActionsSet.validateMaintenance.trigger(
          {
            data,
          }
        )
      );
    },
    [dispatch]
  );

  const disableFormFields: MaintenanceCreateFieldDisability = {
    roomNumbers: true,
  };

  useComponentDidUpdateEffect(() => {
    backward();
  }, [maintenanceCreateState.version]);

  const showSpinner =
    !roomState.didInitialize ||
    roomState.isChanging ||
    roomState.isFetching ||
    maintenanceCreateState.isPending;

  if (!roomState.room && roomState.didInitialize) {
    backward();
  }

  if (showSpinner) {
    return (
      <ac-loader-covering data-test-selector="create-maintenance-spinner" />
    );
  }

  return (
    <>
      {roomState.room && (
        <MaintenanceForm
          backward={backward}
          initialValues={{
            businessDate,
            roomNumbers: roomState.room.unifiedRoomDetails.roomNumber,
          }}
          title={t('MAINTENANCE_CREATE.TITLE.ADD')}
          roomDetails={roomState.room.unifiedRoomDetails}
          disableFields={disableFormFields}
          onSubmit={onSubmit}
          onValidateMaintenance={onValidateMaintenance}
        />
      )}
    </>
  );
};

export default memo(HousekeepingCreateMaintenance);
