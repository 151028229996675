/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksIndividualProfile } from '@hkm/components/TaskManagement/models/profiles';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import CommunicationChannels from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/CommunicationChannels/CommunicationChannels';
import { getProfileAddress } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/helpers';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import { Preferences } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Preferences/Preferences';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';

import { FlexDirection } from '@ac/mobile-components/dist/enums';
import { BadgeSize, Orientation } from '@ac/web-components';

const IndividualProfile = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const { membershipLevels, attributes } = useSelector(getRelationsState);
  const profile = linkedObject?.object as TasksIndividualProfile;

  const profileAddress = useMemo(
    () => getProfileAddress(profile.addresses),
    [profile.addresses]
  );

  const preferences = useMemo(() => {
    const attributesCodes = (profile.preferences || []).map(
      ({ code }) => code ?? ''
    );

    return (
      <Preferences attributesCodes={attributesCodes} attributes={attributes} />
    );
  }, [profile.preferences, attributes]);

  const memberships = useMemo(
    () =>
      profile.memberships?.length
        ? profile.memberships.map(
            ({ number: membershipNumber, levelCode, typeCode }, index) => {
              const badgeColor =
                membershipLevels.length && levelCode
                  ? membershipLevels.find(
                      ({ code: membershipLevelCode }) =>
                        membershipLevelCode === levelCode.code
                    )?.color
                  : BadgeColor.Black;

              return (
                <Fragment key={membershipNumber}>
                  <ac-badge
                    class="ac-spacing-right-sm ac-spacing-bottom-sm"
                    content={typeCode?.description}
                    color={badgeColor}
                    data-test-selector={`task-membership-${index}`}
                    size={BadgeSize.lg}
                  />
                </Fragment>
              );
            }
          )
        : undefined,
    [profile.memberships, membershipLevels]
  );

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-text-group
        class="ac-spacing-top-sm ac-spacing-bottom-sm"
        orientation={Orientation.vertical}
        label={t(
          'TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.INDIVIDUAL_PROFILE'
        )}
        data-test-selector="task-profile-name"
      >
        {profile.displayName}
      </ac-text-group>
      <Accordion
        header={
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.PREFERENCES')}
          >
            {profile.preferences?.length ? preferences : '-'}
          </ac-text-group>
        }
        id={profile.id}
      >
        <CommunicationChannels />

        <ac-text-group
          class="ac-spacing-bottom-sm"
          orientation={Orientation.vertical}
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.ADDRESS')}
          data-test-selector="task-profile-address"
        >
          {profileAddress ?? '-'}
        </ac-text-group>
        <ac-text-group
          class="ac-spacing-bottom-sm"
          orientation={Orientation.vertical}
          label={t(
            'TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.VIP_AND_MEMBERSHIP'
          )}
        >
          {profile.details?.vipCode && (
            <ac-badge
              class="ac-spacing-right-sm ac-spacing-bottom-sm"
              content={`${profile.details?.vipCode.code}`}
              size={BadgeSize.lg}
              color={profile.details?.vipCode.color || BadgeColor.Warning}
              data-test-selector="task-profile-vip"
            />
          )}
          {memberships}
          {!profile.details?.vipCode && !memberships && '-'}
        </ac-text-group>
      </Accordion>
    </ac-flex>
  );
};

export default IndividualProfile;
