/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { TasksHousekeepingRoom } from '@hkm/components/TaskManagement/models/housekeeping';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import OverflowEllipsis from '@hkm/components/TaskManagement/shared/components/OverflowEllipsis/OverflowEllipsis';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';
import { Preferences } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Preferences/Preferences';
import RoomReservationAccompanyingGuest from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/RoomReservationAccompanyingGuest/RoomReservationAccompanyingGuest';
import RoomSharedReservations from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/RoomSharedReservations/RoomSharedReservations';
import BadgeHelpers from '@hkm/components/TaskManagement/utils/badgeHelpers';
import { getProfileName } from '@hkm/components/TaskManagement/utils/getProfileName';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';
import dayjs, { ConfigType } from 'dayjs';

import {
  HousekeepingRoomReservationDetailsDto,
  TitleOrders,
} from '@ac/library-api';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import {
  AlignItems,
  FlexDirection,
  MobileColor,
} from '@ac/mobile-components/dist/enums';
import { BadgeSize, IconName, Orientation } from '@ac/web-components';

const Room = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const { attributes } = useSelector(getRelationsState);
  const room = linkedObject?.object as TasksHousekeepingRoom;
  const effectiveValues = useSelector(selectEffectiveValues);
  const nameSetting = effectiveValues?.titleOrder
    ? effectiveValues.titleOrder
    : TitleOrders.LastNameFirstNameTitle;

  const earliestReservation = useMemo(() => {
    return (room.reservations || [])
      .concat()
      .sort(
        (
          a: HousekeepingRoomReservationDetailsDto,
          b: HousekeepingRoomReservationDetailsDto
        ) =>
          dayjs(a.eta || a.arrivalDate).isAfter(
            b.eta || (b.arrivalDate as ConfigType)
          )
            ? 1
            : -1
      )
      .shift();
  }, [room.reservations]);
  const hasVipOrMembership = earliestReservation
    ? !!earliestReservation.memberships?.length ||
      !!earliestReservation.guest?.vipStatus?.code
    : false;

  const hasSharedReservations = !!room?.reservations?.filter(
    (reservation) => reservation.isRoomShared
  ).length;

  const preferences = useMemo(() => {
    const attributesCodes = (attributes || [])
      .filter(({ id: attributeId }) =>
        (room.attributeIds || []).find((id) => id === attributeId)
      )
      .map(({ code }) => code);

    return (
      <Preferences attributesCodes={attributesCodes} attributes={attributes} />
    );
  }, [room.attributeIds, attributes]);

  const guestName = useMemo(() => {
    return earliestReservation
      ? getProfileName({
          firstName: earliestReservation?.guest?.personalData?.name?.firstName,
          lastName: earliestReservation?.guest?.personalData?.name?.lastName,
          title:
            earliestReservation?.guest?.personalData?.name?.titleCode
              ?.description,
          nameSetting,
        })
      : '-';
  }, [earliestReservation, nameSetting]);

  const occupancy = useMemo(() => {
    return earliestReservation?.occupancyDetails
      ? `${earliestReservation.occupancyDetails[0].adultCount} / ${earliestReservation.occupancyDetails[0].childCount}`
      : '-';
  }, [earliestReservation]);

  const reservationStatus = useMemo(() => {
    const hasStatus = !!earliestReservation?.status?.code;

    if (hasStatus) {
      return (
        <ac-flex alignItems={AlignItems.center}>
          <ac-badge
            class="ac-spacing-right-sm"
            content={earliestReservation?.status?.code}
            color={BadgeHelpers.getReservationStatusColor(
              earliestReservation?.status?.code ?? BadgeColor.Black
            )}
            size={BadgeSize.lg}
            data-test-selector="task-reservation-status"
          />
          <OverflowEllipsis
            text={earliestReservation?.status?.description ?? ''}
            testSelector="task-reservation-status-description"
          />
        </ac-flex>
      );
    }

    return '-';
  }, [earliestReservation?.status]);

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-text-group
        class="ac-spacing-top-sm ac-spacing-bottom-sm"
        orientation={Orientation.vertical}
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ROOM_NUMBER')}
        data-test-selector="task-room-number"
      >
        {room.code ? room.code : '-'}
      </ac-text-group>
      <Accordion
        header={
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ROOM_STATUS')}
          >
            {room.roomStatus ? (
              <ac-flex alignItems={AlignItems.center}>
                <ac-badge
                  class="ac-spacing-right-sm"
                  content={room.roomStatus.code}
                  color={BadgeHelpers.getRoomStatusColor(
                    room.roomStatus?.code ?? BadgeColor.Black
                  )}
                  size={BadgeSize.lg}
                  data-test-selector="task-room-status"
                />
                <OverflowEllipsis
                  text={room.roomStatus?.description ?? ''}
                  testSelector="task-room-status-description"
                />
              </ac-flex>
            ) : (
              '-'
            )}
          </ac-text-group>
        }
        id={room.id}
      >
        <ac-text-group
          class="ac-spacing-bottom-sm"
          orientation={Orientation.vertical}
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.PREFERENCES')}
        >
          {room.attributeIds.length ? preferences : '-'}
        </ac-text-group>
        <AreaGrid>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.GUEST')}
            data-test-selector="task-room-occupancy"
          >
            {occupancy}
          </ac-text-group>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ROOM_TYPE')}
            data-test-selector="task-room-type"
          >
            {room.roomType?.code ?? '-'}
          </ac-text-group>
        </AreaGrid>
        <ac-text-group
          class="ac-spacing-bottom-sm"
          orientation={Orientation.vertical}
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.RESERVATION_STATUS')}
          data-test-selector="task-room-guest"
        >
          {reservationStatus}
        </ac-text-group>
        <ac-text-group
          class="ac-spacing-bottom-sm"
          orientation={Orientation.vertical}
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.IN_USE')}
          data-test-selector="task-room-in-use"
        >
          {guestName}
          {hasVipOrMembership && (
            <AcIndicator
              className="ac-spacing-left-sm"
              icon={IconName.vip}
              backgroundColor={MobileColor.Warning}
              testSelector="task-vip-and-membership-indicator"
            />
          )}
          {!!earliestReservation?.guest?.disabilityStatuses?.length && (
            <AcIndicator
              className="ac-spacing-left-sm"
              icon={IconName.disability}
              backgroundColor={MobileColor.Alert}
              testSelector="task-disability-indicator"
            />
          )}
        </ac-text-group>

        {hasVipOrMembership && (
          <ac-text-group
            class="ac-spacing-bottom-sm"
            orientation={Orientation.vertical}
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.VIP_AND_MEMBERSHIP'
            )}
          >
            {earliestReservation?.guest?.vipStatus && (
              <ac-badge
                class="ac-spacing-right-sm ac-spacing-bottom-sm"
                content={earliestReservation?.guest?.vipStatus.code}
                color={
                  earliestReservation?.guest?.vipStatus.color ||
                  BadgeColor.Warning
                }
                size={BadgeSize.lg}
                data-test-selector="task-guest-vip"
              />
            )}
            {!!earliestReservation?.memberships?.length &&
              earliestReservation?.memberships.map((membership, index) => (
                <ac-badge
                  class="ac-spacing-right-sm ac-spacing-bottom-sm"
                  key={membership.id}
                  content={membership.membershipType?.code}
                  color={membership.membershipLevel?.color || BadgeColor.Alert}
                  size={BadgeSize.lg}
                  data-test-selector={`task-membership-${index}`}
                />
              ))}
          </ac-text-group>
        )}
        {earliestReservation &&
          !!earliestReservation?.guest?.disabilityStatuses?.length && (
            <ac-text-group
              class="ac-spacing-bottom-sm"
              orientation={Orientation.vertical}
              label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.SPECIAL_NEEDS')}
            >
              {earliestReservation?.guest?.disabilityStatuses.map(
                (code, index) => (
                  <ac-badge
                    class="ac-spacing-right-sm ac-spacing-bottom-sm"
                    key={code.code}
                    content={code.code}
                    color={code.color || BadgeColor.Warning}
                    size={BadgeSize.lg}
                    data-test-selector={`task-room-disability-code-${index}`}
                  />
                )
              )}
            </ac-text-group>
          )}
        {hasSharedReservations &&
          room.reservations?.length &&
          room.reservations?.length > 1 && (
            <ac-text-group
              class="ac-spacing-bottom-sm"
              orientation={Orientation.vertical}
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.SHARES_RESERVATION'
              )}
            >
              <RoomSharedReservations
                roomReservations={room.reservations}
                earliestReservation={earliestReservation}
              />
            </ac-text-group>
          )}
        {!!earliestReservation?.accompanyingGuests?.length && (
          <ac-text-group
            orientation={Orientation.vertical}
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ROOM.ACCOMPANYING_GUEST'
            )}
          >
            {earliestReservation.accompanyingGuests
              .splice(0, 5)
              .map((guest, index) => (
                <RoomReservationAccompanyingGuest
                  key={guest.id}
                  guest={guest}
                  index={index}
                />
              ))}
          </ac-text-group>
        )}
      </Accordion>
    </ac-flex>
  );
};

export default Room;
