/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as batchActions from '@hkm/components/Housekeeping/Batch/domain/actions';
import {
  selectChangeBatchRoomsStatusResult,
  selectIsChangeBatchStatusPending,
} from '@hkm/components/Housekeeping/Batch/domain/selectors';
import BatchStatusChange from '@hkm/components/Housekeeping/Batch/statusChange/BatchStatusChange';
import BatchStatusResult from '@hkm/components/Housekeeping/Batch/statusResult/BatchStatusResult';
import * as dashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import APP_ROUTES from '@hkm/constants/routing.constants';
import {
  checkActionsStatus,
  ResultStatus,
} from '@hkm/shared/helpers/actionResult';

const BatchContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const batchChangeResult = useSelector(selectChangeBatchRoomsStatusResult);
  const isPending = useSelector(selectIsChangeBatchStatusPending);
  const batchChangeStatus = checkActionsStatus(batchChangeResult);

  useEffect(() => {
    return () => {
      dispatch(dashboardActions.pagination.refreshPage());
      dispatch(batchActions.clearBatchRoomsStatusResult());
    };

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (batchChangeStatus === ResultStatus.Success) {
      navigate(APP_ROUTES.HOUSEKEEPING.DASHBOARD);
    }
  }, [batchChangeStatus, navigate]);

  if (isPending) {
    return <ac-loader-covering />;
  }

  return (
    <>
      {batchChangeStatus === ResultStatus.Failed && batchChangeResult && (
        <BatchStatusResult result={batchChangeResult} />
      )}
      {batchChangeStatus === ResultStatus.Indefinite && <BatchStatusChange />}
    </>
  );
};

export default memo(BatchContainer);
