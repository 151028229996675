/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import NotificationConsumerManager from '@hkm/components/App/notificationConsumer/NotificationConsumerManager';
import { useInitAuthorization } from '@hkm/features/app/features/container/behaviours/useInitAuthorization';
import { AppContainerError } from '@hkm/features/app/features/container/features/Error';
import { AppLayout } from '@hkm/features/app/features/container/Layout';
import { AppModals } from '@hkm/features/app/modals/Modals';
import { AppModalsPresenterProviders } from '@hkm/features/app/modals/Providers';
import { AppPanels } from '@hkm/features/app/panels/Panels';
import { AppPanelsPresenterProviders } from '@hkm/features/app/panels/Providers';
import { ActivityContextProvider } from '@hkm/features/app/store/activity/Provider';
import { useAppContext } from '@hkm/features/app/store/app/context';
import { MobileFeaturesContextProvider } from '@hkm/features/app/store/features/Provider';

import {
  FrontendCommunicationContextProvider,
  ShijiEnterprisePlatformConfigurableFeature,
} from '@ac/react-infrastructure';

export const AppContainer = (): JSX.Element => {
  const { state } = useAppContext();

  useInitAuthorization();

  if (!state.platformConfiguration) {
    return <ac-loader-covering />;
  }

  if (state.properties.length <= 0) {
    return <AppContainerError />;
  }

  return (
    <ShijiEnterprisePlatformConfigurableFeature
      config={state.platformConfiguration}
    >
      <MobileFeaturesContextProvider>
        <FrontendCommunicationContextProvider>
          <NotificationConsumerManager>
            <AppModalsPresenterProviders>
              <AppPanelsPresenterProviders>
                <ActivityContextProvider>
                  <AppLayout />
                  <AppPanels />
                  <AppModals />
                </ActivityContextProvider>
              </AppPanelsPresenterProviders>
            </AppModalsPresenterProviders>
          </NotificationConsumerManager>
        </FrontendCommunicationContextProvider>
      </MobileFeaturesContextProvider>
    </ShijiEnterprisePlatformConfigurableFeature>
  );
};
