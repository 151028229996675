import i18n, { TOptions } from 'i18next';

import { ApiError, ApiErrorDetail } from '@ac/library-api';

import { ErrorParameterHandlers } from '../errors/errorHandlers';
import { handleErrorParameters } from '../errors/utils';

export interface ApiErrorException {
  data: ApiError;
}
export type ErrorWithMessage = ApiError | Error | ApiErrorException;

export function parseErrorToKibanaFormat(error: ErrorWithMessage): string {
  if (isError(error)) {
    return error.message;
  }

  const { details, message } = isErrorException(error) ? error.data : error;

  if (!(Array.isArray(details) && details.length > 0)) {
    return message;
  }

  return details.map((detail) => detail.message).join(' | ');
}

export async function getErrorMessage(
  error: ErrorWithMessage,
  options?: TOptions
) {
  if (isError(error)) {
    return error.message;
  }

  const defaultMsg = i18n.t('GLOBAL.ERRORS.GENERAL');
  const { details, message } = isErrorException(error) ? error.data : error;

  if (!(Array.isArray(details) && details.length > 0)) {
    return message;
  }

  const messageCreator = await Promise.all(
    details.map((detail: ApiErrorDetail) =>
      localizeErrorMessage(detail, options)
    )
  );

  return messageCreator.join(' ') || defaultMsg;
}

async function localizeErrorMessage(error: ApiErrorDetail, options?: TOptions) {
  const { code, message, parameters } = error;
  const fetchedParameters = await handleErrorParameters(
    ErrorParameterHandlers,
    parameters
  );
  const interpolationConfig = options
    ? { ...options, defaultValue: message, replace: fetchedParameters }
    : { defaultValue: message, replace: fetchedParameters };

  return i18n.t(`ERROR.${code}`, interpolationConfig);
}

const isErrorException = (
  error: ErrorWithMessage
): error is ApiErrorException => {
  return (error as ApiErrorException).data !== undefined;
};

const isError = (error: ErrorWithMessage): error is Error => {
  return (error as Error).name !== undefined;
};
