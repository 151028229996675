/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo } from 'react';
import { Chips } from '@hkm/components/TaskManagement/Dashboard/config/chipsConfig';

import { ChipsType } from '@ac/web-components';

interface ComponentProps {
  chips: Chips;
}

const DashboardFiltersChipsItem: FC<ComponentProps> = (props) => {
  return (
    <ac-chips
      text={props.chips.fieldDisplayValue}
      onRemoveCallback={() => props.chips.clearFieldCallback()}
      data-test-selector={`chips-${props.chips.fieldDisplayValue}`}
      type={ChipsType.input}
    />
  );
};

export default memo(DashboardFiltersChipsItem);
