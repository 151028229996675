/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksGroup } from '@hkm/components/TaskManagement/models/groups';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';

import { FlexDirection, MobileColor } from '@ac/mobile-components/dist/enums';
import { BadgeSize, Orientation } from '@ac/web-components';

const Group = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const { groupStatuses } = useSelector(getRelationsState);
  const group = linkedObject?.object as TasksGroup;

  const statusColor = useMemo(() => {
    return group.status
      ? groupStatuses.find(({ code }) => code === group.status?.code)?.color
      : MobileColor.Black;
  }, [groupStatuses, group.status]);

  const roomNights = useMemo(() => {
    const roomNightsKeys = group.roomNights
      ? Object.keys(group.roomNights)
      : [];
    const filteredRoomNights = roomNightsKeys.filter((key) =>
      group.roomNights ? group.roomNights[key] > 0 : []
    );

    if (filteredRoomNights.length) {
      return filteredRoomNights
        .map(
          (key) => `
            ${t(
              `TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.ROOM_NIGHTS.${key.toUpperCase()}`
            )} (${group.roomNights ? group.roomNights[key] : []})`
        )
        .join(', ');
    }

    return '-';
  }, [t, group.roomNights]);

  return (
    <ac-flex direction={FlexDirection.column}>
      {group.name && (
        <ac-text-group
          class="ac-spacing-top-sm ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.NAME')}
          orientation={Orientation.vertical}
          data-test-selector="task-group-name"
        >
          {group.name}
        </ac-text-group>
      )}
      <Accordion
        header={
          <ac-text-group
            class="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.STATUS')}
            orientation={Orientation.vertical}
          >
            {group.status ? (
              <ac-badge
                content={group.status.code}
                color={statusColor}
                data-test-selector="task-group-status"
                size={BadgeSize.lg}
              />
            ) : (
              '-'
            )}
          </ac-text-group>
        }
        id={group.id}
      >
        <ac-text-group
          class="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.CODE')}
          orientation={Orientation.vertical}
          data-test-selector="task-group-code"
        >
          {group.key ?? '-'}
        </ac-text-group>
        <AreaGrid>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.START_DATE')}
            orientation={Orientation.vertical}
            data-test-selector="task-group-start-date"
          >
            {group.startDate}
          </ac-text-group>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.END_DATE')}
            orientation={Orientation.vertical}
            data-test-selector="task-group-end-date"
          >
            {group.endDate}
          </ac-text-group>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.ROOM_NIGHTS.TITLE'
            )}
            orientation={Orientation.vertical}
            data-test-selector="task-group-room-nights"
          >
            {roomNights}
          </ac-text-group>
          <ac-text-group
            class="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.CUTOFF')}
            orientation={Orientation.vertical}
            data-test-selector="task-group-cut-off"
          >
            {group.cutoffDate ?? '-'}
          </ac-text-group>
          <ac-text-group
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.DECISION')}
            orientation={Orientation.vertical}
            data-test-selector="task-group-decision-date"
          >
            {group.decisionDate ?? '-'}
          </ac-text-group>
          <ac-text-group
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.FOLLOW_UP')}
            orientation={Orientation.vertical}
            data-test-selector="task-group-follow-up"
          >
            {group.nextStepDueDate ?? '-'}
          </ac-text-group>
        </AreaGrid>
      </Accordion>
    </ac-flex>
  );
};

export default Group;
