import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGuestServiceValue } from '@hkm/components/Attendant/shared/utils/useGuestServiceValue';
import {
  selectActiveProperty,
  selectEffectiveValues,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DatetimeFormElement, {
  DatetimeVariant,
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import { GuestServiceRequestField } from '@hkm/components/shared/GuestService/adHoc/GuestServiceRequestField';
import { GuestServiceModalFormState } from '@hkm/components/shared/GuestService/modal/form/useFormState';
import GuestServiceModal, {
  GuestServiceModalMode,
} from '@hkm/components/shared/GuestService/modal/GuestServiceModal';
import { GuestServiceStatusField } from '@hkm/components/shared/GuestService/planned/GuestServiceStatusField';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { createLocalTimeFromTzTime } from '@hkm/utils/dateHelper';

import { AcSelectField } from '@ac/mobile-components/dist/components/select';
import { MobileColor } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface GuestServiceRoom {
  doNotDisturb: boolean;
  greenService: boolean;
  serviceDeferred: boolean;
  serviceDeferredUntil?: string;
  serviceRefused: boolean;
  serviceRequested: boolean;
  servicePreferred: boolean;
  serviceRequestedInstruction?: string;
  servicePreferredAfterTime?: string;
  currentMainReservation?: UnifiedReservationDetails;
}

export interface GuestServiceFieldProps extends Testable {
  isReadonly?: boolean;
  room: GuestServiceRoom;
  onChange: (guestServiceModalFormState?: GuestServiceModalFormState) => void;
}

/* tslint:disable:jsx-no-lambda */
const GuestServiceField: FC<GuestServiceFieldProps> = ({
  isReadonly,
  testSelector,
  room,
  onChange,
}) => {
  const { t } = useTranslation();
  const { activeGuestServices, isServiceActive } = useGuestServices();
  const effectiveValues = useSelector(selectEffectiveValues);
  const isHousekeepingCleaningScheduleEnabled =
    effectiveValues?.enabledHousekeepingSchedule;
  const [guestServiceModalVisibility, setGuestServiceModalVisibility] =
    useState<GuestServiceModalMode | undefined>(undefined);

  const { getServiceValue } = useGuestServiceValue();

  const property = useSelector(selectActiveProperty);
  const deferredTime = createLocalTimeFromTzTime(
    room.serviceDeferredUntil,
    property?.timeZoneCode
  );

  const selectedService = getServiceValue(room);

  const showGuestServiceModal = () =>
    setGuestServiceModalVisibility(GuestServiceModalMode.AdHocPlanned);
  const hideGuestServiceModal = () => setGuestServiceModalVisibility(undefined);
  const clearedSelectedValue = useCallback(
    (initialServiceType: GuestServiceStatusType | undefined) =>
      onChange({
        initialServiceType,
      }),
    [onChange]
  );

  const deferredTimeComponent = useMemo(
    () =>
      deferredTime &&
      room.serviceDeferred &&
      activeGuestServices
        .map((guestService) => guestService.code)
        .includes(GuestServiceStatusType.ServiceDeferred) ? (
        <DatetimeFormElement
          color={MobileColor.ServiceDeferred}
          datetime={deferredTime}
          variant={DatetimeVariant.onlyTime}
          timeLabel={t('GLOBAL.GUEST_SERVICE_STATUS.PREFERRED_TIME')}
        />
      ) : (
        <></>
      ),
    [activeGuestServices, room.serviceDeferred, t, deferredTime]
  );

  const onOpenModal = useCallback((mode: GuestServiceModalMode) => {
    setGuestServiceModalVisibility(mode);
  }, []);

  return (
    <>
      {isHousekeepingCleaningScheduleEnabled ? (
        <>
          <GuestServiceStatusField
            clearSelectedValue={onChange}
            disabled={!room.currentMainReservation}
            onOpenModal={onOpenModal}
            testSelector={testSelector}
            isReadonly={!!isReadonly}
            room={room}
          />
          <GuestServiceRequestField
            clearSelectedValue={onChange}
            disabled={!room.currentMainReservation}
            onOpenModal={onOpenModal}
            testSelector={testSelector}
            isReadonly={!!isReadonly}
            room={room}
          />
        </>
      ) : (
        <>
          {activeGuestServices.length > 0 &&
            (!isReadonly || selectedService) &&
            (isReadonly ? (
              <>
                <ac-text-group
                  label={t('GLOBAL.GUEST_SERVICE_STATUS.TITLE')}
                  data-test-selector={formatTestSelector(
                    testSelector,
                    'guestServiceStatus'
                  )}
                >
                  {selectedService?.name}
                </ac-text-group>
                {deferredTimeComponent}
              </>
            ) : (
              <>
                <AcSelectField
                  disabled={!room.currentMainReservation}
                  clearable={true}
                  clearSelectedValue={() =>
                    clearedSelectedValue(
                      selectedService?.code as GuestServiceStatusType
                    )
                  }
                  onClick={showGuestServiceModal}
                  placeholder={t('GLOBAL.SELECT')}
                  value={getServiceValue(room)?.name || ''}
                  label={t('GLOBAL.GUEST_SERVICE_STATUS.TITLE')}
                  testSelector={formatTestSelector(
                    testSelector,
                    'guestServiceStatus'
                  )}
                  validation={
                    selectedService?.code &&
                    !isServiceActive(
                      selectedService.code as GuestServiceStatusType
                    )
                      ? t('GLOBAL.GUEST_SERVICE_STATUS.SERVICE_INACTIVE', {
                          service: t(
                            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${selectedService.code}`
                          ),
                        })
                      : ''
                  }
                />
                {deferredTimeComponent}
              </>
            ))}
        </>
      )}
      <GuestServiceModal
        onConfirm={onChange}
        onClose={hideGuestServiceModal}
        mode={guestServiceModalVisibility}
        testSelector={`${testSelector}-guest-service`}
        room={room}
      />
    </>
  );
};

export default memo(GuestServiceField);
