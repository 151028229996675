/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AttendantTaskProgressType } from '@ac/library-api';
import {
  AlignItems,
  CounterColor,
  CounterType,
  FlexGap,
  TextColor,
} from '@ac/web-components';

interface Props {
  status: AttendantTaskProgressType;
  className?: string;
  label?: string;
}

export const taskProgressColorMap = new Map<
  AttendantTaskProgressType,
  CounterColor
>()
  .set(AttendantTaskProgressType.InProgress, CounterColor.info)
  .set(AttendantTaskProgressType.Skipped, CounterColor.alert)
  .set(AttendantTaskProgressType.Paused, CounterColor.gray)
  .set(AttendantTaskProgressType.Completed, CounterColor.success)
  .set(AttendantTaskProgressType.Pending, CounterColor.warning);

export const taskProgressTextColorMap = new Map<
  AttendantTaskProgressType,
  TextColor
>()
  .set(AttendantTaskProgressType.InProgress, TextColor.primaryAction)
  .set(AttendantTaskProgressType.Skipped, TextColor.alert)
  .set(AttendantTaskProgressType.Paused, TextColor.gray3)
  .set(AttendantTaskProgressType.Completed, TextColor.success)
  .set(AttendantTaskProgressType.Pending, TextColor.warning);

const AttendantTaskProgressTypeBadge: FC<Props> = ({
  status,
  label,
  className,
}) => {
  const { t } = useTranslation();

  const color: CounterColor =
    taskProgressColorMap.get(status) || CounterColor.gray;
  const labelText = label || t(`GLOBAL.TASK_PROGRESS_TYPE.${status}`);
  const flexClass = classNames('attendant-task-type-badge', className);

  return (
    <ac-flex
      class={flexClass}
      alignItems={AlignItems.center}
      columnGap={FlexGap.xs}
    >
      <ac-counter color={color} type={CounterType.dot} />
      {labelText}
    </ac-flex>
  );
};

export default memo(AttendantTaskProgressTypeBadge);
