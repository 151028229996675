/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TasksReservation } from '@hkm/components/TaskManagement/models/reservations';
import InnerAccordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/InnerAccordion/InnerAccordion';
import SpecialNeeds from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/SpecialNeeds/SpecialNeeds';
import VipAndMember from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/VipAndMember/VipAndMember';

import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { MobileColor, TextSize } from '@ac/mobile-components/dist/enums';
import { IconName, Orientation } from '@ac/web-components';

interface ComponentProps {
  reservation: TasksReservation;
  index: number;
}

const RoomSharedReservationGuest = (props: ComponentProps) => {
  const { reservation, index } = props;
  const { t } = useTranslation();
  const guest = reservation.guestDetails;
  const hasVipOrMembership =
    guest?.vipStatusCode || !!reservation.memberships.length;
  const requireAccordion =
    !!guest?.disabilityCodes.length || hasVipOrMembership;

  const guestInfo = useMemo(
    () => (
      <>
        <AcText
          size={TextSize.Main2}
          className="ac-spacing-right-sm"
          testSelector={`task-accompanying-guest-name-${index}`}
        >
          {reservation.reservationGuestName}
        </AcText>

        {hasVipOrMembership && (
          <AcIndicator
            className="ac-spacing-right-sm"
            icon={IconName.vip}
            backgroundColor={MobileColor.Warning}
            testSelector={`task-shared-reservation-guest-vip-indicator-${index}`}
          />
        )}
        {reservation.hasDisability && (
          <AcIndicator
            icon={IconName.disability}
            backgroundColor={MobileColor.Alert}
            testSelector={`task-shared-reservation-guest-disability-indicator-${index}`}
          />
        )}
      </>
    ),
    [
      hasVipOrMembership,
      index,
      reservation.hasDisability,
      reservation.reservationGuestName,
    ]
  );

  return (
    <>
      {requireAccordion ? (
        <InnerAccordion header={guestInfo} id={guest?.id || ''}>
          {hasVipOrMembership && (
            <ac-text-group
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.VIP_AND_MEMBERSHIP'
              )}
              orientation={Orientation.vertical}
              class="ac-spacing-top-md"
            >
              <VipAndMember
                vipStatusCode={guest?.vipStatusCode}
                memberships={guest?.disabilityCodes}
              />
            </ac-text-group>
          )}
          {reservation.hasDisability && (
            <ac-text-group
              label={t(
                'TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.SPECIAL_NEEDS'
              )}
              orientation={Orientation.vertical}
            >
              <SpecialNeeds disabilityCodes={guest?.disabilityCodes} />
            </ac-text-group>
          )}
        </InnerAccordion>
      ) : (
        guestInfo
      )}
    </>
  );
};

export default RoomSharedReservationGuest;
