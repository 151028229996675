import { Store } from '@hkm/store/interfaces/store';
import { PaginationActionSet } from '@hkm/store/pagination/paginationActionsFactory';
import { PaginationState } from '@hkm/store/pagination/paginationState';
import { Console } from '@hkm/utils/console';
import {
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';

import { PageResponse } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

export function createPaginationSaga<RawT, ItemT, SortT, FilterT>(
  actionSet: PaginationActionSet<RawT, ItemT, SortT, FilterT>,
  stateSelector: (state: Store) => PaginationState<RawT, ItemT, SortT, FilterT>
) {
  function* clearableAutoRefresh() {
    while (true) {
      const task: Task = yield fork(autoRefresh);
      yield take(actionSet.clearPage);
      yield cancel(task);
    }
  }

  function* autoRefresh(): unknown {
    const startState: PaginationState<RawT, ItemT, SortT, FilterT> =
      yield select(stateSelector);

    // Optionally start with first page and wait until we have a page to refresh from
    if (!startState.isLoading) {
      yield put(actionSet.fetchPage(startState.lastPageNumber || 1));
    }
    yield take(actionSet.validPageReceived);

    while (true) {
      // Wait for timeout
      const [refresh, sort, filter] = yield race([
        take(actionSet.refreshPage),
        take(actionSet.setSort),
        take(actionSet.setFilters),
        take(actionSet.validPageReceived),
        take(actionSet.fetchPage),
      ]);

      // Fetch 1st page on sort/filter change, fetch same page on cool down end
      if (refresh || sort || filter) {
        const state: PaginationState<RawT, ItemT, SortT, FilterT> =
          yield select(stateSelector);
        const pageNumber =
          sort || filter || !state.page?.paging.pageNumber
            ? 1
            : state.page.paging.pageNumber;
        yield put(actionSet.fetchPage(pageNumber));
      }
    }
  }

  function* fetchedPageValidation(action: Action<PageResponse<RawT, ItemT>>) {
    const meta = action.payload.paging;
    const maximumPageNumber = Math.max(
      1,
      Math.ceil(meta.totalCount / meta.pageSize)
    );

    if (meta.pageNumber > maximumPageNumber) {
      yield put(actionSet.fetchPage(maximumPageNumber));
    } else {
      yield put(actionSet.validPageReceived(action.payload));
    }
  }

  function* onError(e: Action<undefined> & string) {
    yield Console.error(e);
  }

  function* enabledPaginationTasks() {
    yield takeLatest(actionSet.fetchedPage, fetchedPageValidation);
    yield takeLatest(actionSet.error, onError);
    while (true) {
      const lastTask: Task = yield fork(clearableAutoRefresh);
      yield take(actionSet.enableAutoRefresh);
      yield cancel(lastTask);
    }
  }

  function* resetToFirstPageWhenDisabled() {
    yield put(actionSet.clearPage());
    yield put(actionSet.clearLastPageNumber());
  }

  function* disabledPaginationTasks() {
    yield takeEvery(actionSet.setFilters, resetToFirstPageWhenDisabled);
    yield takeEvery(actionSet.setSort, resetToFirstPageWhenDisabled);
  }

  return function* () {
    while (true) {
      const disabledTasks: Task = yield fork(disabledPaginationTasks);
      yield take(actionSet.enableAutoRefresh);
      yield cancel(disabledTasks);

      const enabledTasks: Task = yield fork(enabledPaginationTasks);
      yield take(actionSet.disableAutoRefresh);
      yield cancel(enabledTasks);
    }
  };
}
