/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChipsItem } from '@hkm/components/shared/Chips/ChipsItem';
import classNames from 'classnames';

import {
  Childless,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { AlignItems, ChipsColor, ChipsType, FlexGap } from '@ac/web-components';

import './Chips.css';

interface ChipsProps extends Styleable, Childless, Testable {
  items: Array<ChipsItem<string>>;
  onRemove: (value: string) => void;
  onClear: () => void;
}

const Chips: FC<ChipsProps> = (props: ChipsProps) => {
  const { t } = useTranslation();

  const className: string = classNames('chips', props.className);

  return (
    <div className={className} style={props.style}>
      <ac-flex
        class="chips-scroll ac-padding-horizontal-sm"
        alignItems={AlignItems.center}
        columnGap={FlexGap.sm}
      >
        <ac-chips
          onClick={props.onClear}
          data-test-selector={'chips-clear-all'}
          color={ChipsColor.gray}
          text={t('GLOBAL.CLEAR_ALL')}
        />
        <ac-flex class="ac-gap-sm">
          {props.items.map((item) => (
            <ac-chips
              key={item.id}
              text={item.label}
              type={ChipsType.input}
              onRemoveCallback={() => props.onRemove(item.value)}
              data-test-selector={`chips-${item.value}`}
            />
          ))}
        </ac-flex>
      </ac-flex>
    </div>
  );
};

export default memo(Chips);
