/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC } from 'react';
import DashboardSort from '@hkm/components/TaskManagement/Dashboard/DashboardSort/DashboardSort';
import DashboardQuickFilterWrapper from '@hkm/components/TaskManagement/Dashboard/DashboardSubheader/DashboardQuickFilterWrapper/DashboardQuickFilterWrapper';

import { AcTile } from '@ac/mobile-components/dist/components/tile';
import { AlignItems, FlexDirection } from '@ac/mobile-components/dist/enums';
import { FlexGap } from '@ac/web-components';

const DashboardSubheader: FC = () => {
  return (
    <AcTile>
      <ac-flex
        rowGap={FlexGap.sm}
        direction={FlexDirection.column}
        alignItems={AlignItems.flexEnd}
      >
        <DashboardQuickFilterWrapper />
        <DashboardSort />
      </ac-flex>
    </AcTile>
  );
};

export default DashboardSubheader;
