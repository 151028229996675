import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';

export function getPostingAttendantReservations(
  reservations?: UnifiedReservationDetails[]
): UnifiedReservationDetails[] {
  return reservations
    ? (reservations
        .map((reservation: UnifiedReservationDetails) => {
          return activeReservationStates.has(
            reservation.status?.code as ReservationStatus
          )
            ? reservation
            : undefined;
        })
        .filter(Boolean) as UnifiedReservationDetails[])
    : [];
}
