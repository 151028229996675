import * as actions from '@hkm/components/App/domain/actions';
import {
  NotesStore,
  ReservationNotes,
} from '@hkm/components/App/domain/interfaces/BookingNotesStore';
import {
  DictionariesStore,
  DictionaryEntry,
} from '@hkm/components/App/domain/interfaces/DictionariesStore';
import {
  getArchivedRoomQueue,
  getDictionaries,
  getNoteTypes,
  selectNotes,
} from '@hkm/components/App/domain/selectors';
import { patchChangedRoom } from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import {
  CustomDictionaryConfig,
  DictionariesConfig,
  DictionaryApi,
  DictionaryConfig,
} from '@hkm/shared/dictionaries/dictionariesConfig';
import { Dictionary } from '@hkm/shared/dictionaries/dictionary';
import {
  ErrorWithMessage,
  getErrorMessage,
} from '@hkm/shared/helpers/getErrorMessage';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';
import i18n from 'i18next';
import { camelCase, startCase } from 'lodash';

import {
  BookingNote,
  buildFIQLFilter,
  CustomerDetails,
  FeatureToggleItem,
  FIQLOperators,
  GenericTypeEntity,
  getDataForAllPages,
  LibraryApiResponse,
  NoteType,
  PageQueryParams,
  PageResponseDto,
  RoomQueueDetailsViewsDto,
  RoomQueueItemDetailsViewsDto,
} from '@ac/library-api';
import { NotesApi } from '@ac/library-api/dist/api/v0/booking/reservations';
import {
  CustomersApi,
  MaintenanceApi,
} from '@ac/library-api/dist/api/v0/configuration';
import { GenericTypeApi } from '@ac/library-api/dist/api/v0/configuration/genericEntities';
import { RoomQueuesApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';
import { isDefined } from '@ac/library-utils/dist/utils';

interface ReservationNotesResult {
  reservationId: string;
  response: PageResponseDto<BookingNote>;
}

const STATIC_REQUIRED_DICTIONARIES: Set<Dictionary> = new Set<Dictionary>().add(
  Dictionary.SupportedUiLanguages
);
const getEntityBasePath = (isRateEntity?: boolean): string => {
  return isRateEntity
    ? '/rate-manager/v0/entities'
    : '/configuration/v0/entities';
};

function* fetchDictionaries(needed: Set<Dictionary | CustomDictionaryConfig>) {
  try {
    const requiredDictionaries = new Set([
      ...needed,
      ...STATIC_REQUIRED_DICTIONARIES,
    ]);
    const configs = DictionariesConfig;
    const current: DictionariesStore = yield select(getDictionaries);

    // Gather missing dictionaries
    const missing = Array.from(requiredDictionaries).filter((need) =>
      typeof need === 'string'
        ? !Array.isArray(current[need])
        : !Array.isArray(current[need.dictionaryName])
    );

    // Create promises
    const promises = missing
      .map((dict) => {
        const dictionaryName =
          typeof dict === 'string' ? dict : dict.dictionaryName;
        // Now, we can pass `CustomDictionaryConfig` as entry to dictionaries Set what allow us to overwrite config definition from `DictionariesConfig` for some dictionary
        const config =
          typeof dict === 'string' ? configs.get(dictionaryName) : dict;
        const api: DictionaryApi = (
          (config as DictionaryApi).getList
            ? config
            : (config as DictionaryConfig).api
        ) as DictionaryApi;
        const filter: string | undefined = (config as DictionaryConfig).filter;
        const includes: string | undefined = (config as DictionaryConfig)
          .includes;

        if ((config as DictionaryConfig).genericEntityConfig) {
          const entityTypeCode = startCase(camelCase(dictionaryName)).replace(
            / /g,
            ''
          );
          const getList = GenericTypeApi.getList;
          const pagePromise = ({ pageNumber, pageSize }: PageQueryParams) =>
            getList(
              entityTypeCode,
              getEntityBasePath(false)
            )({
              queryParams: {
                pageNumber,
                pageSize,
                sort: 'code',
                filter,
                includes,
              },
            }) as Promise<PageResponseDto<GenericTypeEntity>>;

          return { [dictionaryName]: getDataForAllPages(pagePromise) };
        }

        const pagePromise = ({ pageNumber, pageSize }: PageQueryParams) =>
          api.getList({
            queryParams: {
              pageNumber,
              pageSize,
              sort: 'code',
              filter,
              includes,
            },
          }) as Promise<PageResponseDto<GenericTypeEntity>>;

        return { [dictionaryName]: getDataForAllPages(pagePromise) };
      })
      .reduce((previous, curr) => ({ ...previous, ...curr }), {});

    // Wait for responses
    const response: Promise<DictionaryEntry[]> = yield all(promises);

    // Get dictionaries from responses and sort them
    const result = Object.entries(response)
      .map(([dictionaryName, value]) => {
        let values: DictionaryEntry[] = [];

        if ((value as unknown as LibraryApiResponse<unknown>).data) {
          values = value.data;
        } else {
          values = Array.isArray(value) ? value : value.results;
        }

        const sorter = (
          configs.get(dictionaryName as Dictionary) as DictionaryConfig
        ).sorter;
        if (sorter) {
          values = values.sort(sorter);
        }

        return { [dictionaryName]: values };
      })
      .reduce(
        (previous, curr) => ({ ...previous, ...curr }),
        {}
      ) as DictionariesStore;

    yield put(actions.fetchDictionaries.success(result));
  } catch (error) {
    yield put(actions.fetchDictionaries.failure(error));
  }
}

function* fetchFeatureToggles() {
  try {
    const featureToggles: FeatureToggleItem[] = yield MaintenanceApi.getList();
    yield put(actions.fetchFeatureToggles.success(featureToggles));
  } catch (error) {
    yield window.ACP?.container.toast.showError({
      message: error.message,
    });
    yield put(actions.fetchFeatureToggles.failure(error));
  }
}

function* handleDictionariesFetch(action: Action<Set<Dictionary>>) {
  const task: Task = yield fork(fetchDictionaries, action.payload);
  yield take(actions.clearDictionaries);
  yield cancel(task);
}

function* handleDisplayExtractedError(action: Action<ErrorWithMessage>) {
  const errorMessage: Promise<string> = yield call(
    getErrorMessage,
    action.payload
  );
  yield window.ACP?.container.toast.showError({
    message: yield errorMessage,
  });
}

function* handleDisplayError(action: Action<string>) {
  yield window.ACP?.container.toast.showError({
    message: action.payload,
  });
}

function* handleDisplayWarning(action: Action<string>) {
  yield window.ACP?.container.toast.showWarning({
    message: action.payload,
  });
}

function* handleDisplaySuccess(action: Action<string>) {
  yield window.ACP?.container.toast.showSuccess({
    message: action.payload,
  });
}

function* fetchCurrentCustomer() {
  try {
    const customerData: CustomerDetails = yield CustomersApi.getCurrent();
    yield put(actions.fetchCurrentCustomer.success(customerData));
  } catch (error) {
    yield put(actions.fetchCurrentCustomer.failure(error));
  }
}

function* fetchBookingNotes(action: Action<string[]>) {
  try {
    const notesStore: NotesStore = yield select(selectNotes);
    const noteTypes: NoteType[] = yield select(getNoteTypes);

    const roleTypesCode = noteTypes.map((note: NoteType) => {
      return note.code;
    });

    const uniqReservationIds = action.payload.filter((reservationId) =>
      notesStore.reservationNotes.every(
        (note) => note.reservationId !== reservationId
      )
    );

    const responses: ReservationNotes[] = yield all(
      uniqReservationIds.map((reservationId) =>
        NotesApi.getList({
          pathParams: { id: reservationId },
          queryParams: {
            filter: buildFIQLFilter(
              'typeCode',
              FIQLOperators.equal,
              roleTypesCode
            ),
            pageNumber: 1,
            pageSize: APP_CONSTANTS.MAX_FETCH_SIZE,
          },
          customConfig: {
            ...getCustomConfig(),
          },
        })
          .then((response) => ({ response, reservationId }))
          .catch(() => ({ response: null, reservationId }))
      )
    );

    const reservationNotes: ReservationNotes[] = responses.map(
      (result: ReservationNotesResult) => {
        return {
          reservationId: result.reservationId,
          notes: result.response.results,
        };
      }
    );

    yield put(actions.fetchReservationNotes.success(reservationNotes));
  } catch (e) {
    yield window.ACP?.container.toast.showError({
      message: i18n.t('GLOBAL.ERRORS.NOTES'),
    });
    yield put(actions.fetchReservationNotes.failure(e));
  }
}

function* fetchRoomQueue() {
  try {
    const roomQueue: RoomQueueDetailsViewsDto =
      yield RoomQueuesApi.getCurrentQueue();
    yield put(actions.fetchRoomQueue.success(roomQueue));
  } catch (error) {
    yield window.ACP?.container.toast.showError({
      message: error.message,
    });
    yield put(actions.fetchRoomQueue.failure(error));
  }
}

function* patchChangedRoomQueue(action: Action<RoomQueueDetailsViewsDto>) {
  const current = action.payload.current ?? [];
  const archivedQueue: RoomQueueDetailsViewsDto | undefined =
    yield select(getArchivedRoomQueue);
  const archivedQueueRooms = archivedQueue?.current ?? [];
  const roomsRemovedFromQueue = archivedQueueRooms.filter(
    (room: RoomQueueItemDetailsViewsDto) =>
      current.findIndex(
        (newQueueRoom) => newQueueRoom.room?.id === room.room?.id
      ) === -1
  );

  const roomsToPatch = [...current, ...roomsRemovedFromQueue]
    .map((room) => room.room?.id)
    .filter(isDefined);
  yield all(roomsToPatch.map((roomId) => put(patchChangedRoom(roomId))));
}

export default function* () {
  yield takeLatest(actions.fetchCurrentCustomer.trigger, fetchCurrentCustomer);
  yield takeLatest(actions.fetchRoomQueue.trigger, fetchRoomQueue);

  yield takeEvery(actions.fetchDictionaries.trigger, handleDictionariesFetch);
  yield takeEvery(actions.fetchReservationNotes.trigger, fetchBookingNotes);
  yield takeLatest(actions.fetchFeatureToggles.trigger, fetchFeatureToggles);
  yield takeEvery(actions.displayExtractedError, handleDisplayExtractedError);
  yield takeEvery(actions.displayWarning, handleDisplayWarning);
  yield takeEvery(actions.displaySuccess, handleDisplaySuccess);
  yield takeEvery(actions.displayError, handleDisplayError);
  yield takeLatest(actions.fetchRoomQueue.success, patchChangedRoomQueue);
}
