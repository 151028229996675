/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router';
import * as attendantActions from '@hkm/components/Attendant/shared/domain/actions';
import { selectAssignedSheetState } from '@hkm/components/Attendant/shared/domain/selectors';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import * as setMaintenanceActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/actions';
import { selectMaintenanceCreateState } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/selectors';
import MaintenanceForm from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/MaintenanceCreateForm';
import { MaintenanceCreateFieldDisability } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/MaintenanceCreateFieldDisability';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { RoomStatus, SetMaintenances } from '@ac/library-api';
import { useComponentDidUpdateEffect } from '@ac/mobile-components/dist/hooks';

const AttendantCreateMaintenance: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { roomId } = params;
  const businessDate = useSelector(selectBusinessDate) ?? '';
  const assignedSheet = useSelector(selectAssignedSheetState);
  const maintenanceCreateState = useSelector(selectMaintenanceCreateState);

  const roomData: AttendantRoom | undefined = useMemo(
    () =>
      assignedSheet.data?.roomAssignments.find((room) => room.id === roomId),
    [assignedSheet, roomId]
  );

  const showSpinner = maintenanceCreateState.isPending;

  const backward = useCallback(() => {
    navigate(
      generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, { roomId })
    );
  }, [navigate, roomId]);

  const onSubmit = useCallback(
    (data: SetMaintenances) => {
      dispatch(
        setMaintenanceActions.setAttendantMaintenanceActionsSet.createMaintenance.trigger(
          {
            data,
            roomId: roomData?.id,
            housekeepingRoomVersion: roomData?.housekeepingRoomVersion,
          }
        )
      );
    },
    [dispatch, roomData?.id, roomData?.housekeepingRoomVersion]
  );

  const onValidateMaintenance = useCallback(
    (data: SetMaintenances) => {
      dispatch(
        setMaintenanceActions.setAttendantMaintenanceActionsSet.validateMaintenance.trigger(
          {
            data,
            roomId: roomData?.id,
          }
        )
      );
    },
    [dispatch, roomData?.id]
  );

  const disableFormFields: MaintenanceCreateFieldDisability = useMemo(
    () => ({
      toTime: true,
      fromTime: true,
      roomNumbers: true,
      returnStatusCode: true,
    }),
    []
  );

  useComponentDidUpdateEffect(() => {
    dispatch(attendantActions.fetchAssignedSheet.trigger());
    backward();
  }, [maintenanceCreateState.version]);

  if (!roomData && assignedSheet.didInitialize) {
    backward();
  }

  if (showSpinner) {
    return (
      <ac-loader-covering data-test-selector="create-maintenance-spinner" />
    );
  }

  return (
    <>
      {roomData && (
        <MaintenanceForm
          onSubmit={onSubmit}
          backward={backward}
          initialValues={{
            businessDate,
            toTime: businessDate,
            fromTime: businessDate,
            returnStatusCode: RoomStatus.DI,
            roomNumbers: roomData.unifiedRoomDetails.roomNumber,
          }}
          disableFields={disableFormFields}
          roomDetails={roomData.unifiedRoomDetails}
          onValidateMaintenance={onValidateMaintenance}
          title={t('MAINTENANCE_CREATE.TITLE.ADD')}
        />
      )}
    </>
  );
};

export default memo(AttendantCreateMaintenance);
