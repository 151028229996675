import { useCallback, useRef } from 'react';

import { WithoutSeverityToast } from '@ac/react-infrastructure/dist/features/notifications/toast';
import { ToastInterface, ToastSeverity } from '@ac/web-components';

type Element = { showToast: (toast: ToastInterface) => void };

export interface NotificationsStore {
  setRef(ref: HTMLAcToastElement | undefined): void;
  show(toast: ToastInterface): void;
  showSuccess(toast: WithoutSeverityToast): void;
  showError(toast: WithoutSeverityToast): void;
  showWarning(toast: WithoutSeverityToast): void;
  showInfo(toast: WithoutSeverityToast): void;
}

export function useNotificationsStore(): NotificationsStore {
  const ref = useRef<Element | undefined>();

  const setRef = (element: HTMLAcToastElement) => {
    ref.current = element;
  };

  const show = useCallback((toast: ToastInterface): void => {
    ref.current?.showToast?.(toast);
  }, []);

  const showSuccess = useCallback((toast: ToastInterface): void => {
    ref.current?.showToast?.({ ...toast, severity: ToastSeverity.success });
  }, []);

  const showError = useCallback((toast: ToastInterface): void => {
    ref.current?.showToast?.({ ...toast, severity: ToastSeverity.error });
  }, []);

  const showWarning = useCallback((toast: ToastInterface): void => {
    ref.current?.showToast?.({ ...toast, severity: ToastSeverity.warning });
  }, []);

  const showInfo = useCallback((toast: ToastInterface): void => {
    ref.current?.showToast?.({ ...toast, severity: ToastSeverity.info });
  }, []);

  return {
    show,
    showSuccess,
    showWarning,
    showError,
    showInfo,
    setRef,
  };
}
