/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  TasksCompanyProfile,
  TasksIndividualProfile,
  TasksTravelAgentProfile,
} from '@hkm/components/TaskManagement/models/profiles';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import { getProfileCommunicationChannel } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/helpers';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';

import { CommunicationMode, ProfileRole } from '@ac/library-api';
import { Orientation } from '@ac/web-components';

interface ComponentProps {
  additonalInfo?: React.ReactNode;
}

const CommunicationChannels: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const profile = linkedObject?.object as
    | TasksIndividualProfile
    | TasksCompanyProfile
    | TasksTravelAgentProfile;

  const profileNumber = useMemo(
    () =>
      getProfileCommunicationChannel(
        CommunicationMode.Phone,
        profile.communicationChannels
      ),
    [profile.communicationChannels]
  );

  const profileEmail = useMemo(
    () =>
      getProfileCommunicationChannel(
        CommunicationMode.Email,
        profile.communicationChannels
      ),
    [profile.communicationChannels]
  );

  const profileWebsite = useMemo(
    () =>
      profile.profileRole !== ProfileRole.Individual
        ? getProfileCommunicationChannel(
            CommunicationMode.Website,
            profile.communicationChannels,
            false
          )
        : undefined,
    [profile.communicationChannels, profile.profileRole]
  );

  return profile.profileRole !== ProfileRole.Individual ? (
    <Accordion
      header={
        <ac-text-group
          class="ac-spacing-top-sm ac-spacing-bottom-sm"
          orientation={Orientation.vertical}
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.PHONE')}
          data-test-selector="task-profile-number"
        >
          {profileNumber ? profileNumber : '-'}
        </ac-text-group>
      }
      id={profile.id}
    >
      <ac-text-group
        class="ac-spacing-bottom-sm"
        orientation={Orientation.vertical}
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.EMAIL')}
        data-test-selector="task-profile-email"
      >
        {profileEmail ? profileEmail : '-'}
      </ac-text-group>
      <ac-text-group
        class="ac-spacing-bottom-sm"
        orientation={Orientation.vertical}
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.WEBSITE')}
        data-test-selector="task-profile-website"
      >
        {profileWebsite ? profileWebsite : '-'}
      </ac-text-group>
      {props.additonalInfo}
    </Accordion>
  ) : (
    <>
      <ac-text-group
        class="ac-spacing-bottom-sm"
        orientation={Orientation.vertical}
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.PHONE')}
        data-test-selector="task-profile-number"
      >
        {profileNumber ?? '-'}
      </ac-text-group>

      <ac-text-group
        class="ac-spacing-bottom-sm"
        orientation={Orientation.vertical}
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.EMAIL')}
        data-test-selector="task-profile-email"
      >
        {profileEmail ?? '-'}
      </ac-text-group>
      {props.additonalInfo}
    </>
  );
};

export default CommunicationChannels;
