import { PagingInfo } from '@ac/library-api';
import {
  Components,
  PaginationParams,
  TargetValueObject,
} from '@ac/web-components';

export function createPaginatorProps(
  paging: PagingInfo,
  onChangeCallback: (params: PaginationParams) => void
): Partial<Components.AcPagination> {
  return {
    page: paging.pageNumber,
    perPage: paging.pageSize,
    total: paging.totalCount,
    showNumberOfItems: true,
    showItemsPerPage: false,
    onChangeCallback,
    targetValue: TargetValueObject.footer,
  };
}
